"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PictureContainer = exports.Content = exports.Title = exports.ButtonCard = void 0;
var polished_1 = require("polished");
var styled_components_1 = __importStar(require("styled-components"));
var typography_1 = require("../typography");
var PICTURE_SIZES = {
    normal: {
        width: polished_1.rem(48),
        height: polished_1.rem(48),
    },
    small: {
        width: polished_1.rem(24),
        height: polished_1.rem(24),
    },
    wide: {
        width: polished_1.rem(100),
        height: polished_1.rem(56),
    },
};
exports.ButtonCard = styled_components_1.default.div.withConfig({ displayName: "ButtonCard", componentId: "sc-p1vlmi" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["display:grid;grid-template-columns:", " 1fr;column-gap:", ";background-color:", ";border:2px solid ", ";border-radius:", ";cursor:pointer;padding:", " ", ";line-height:1.5;align-items:center;flex-wrap:wrap;", ";:active,:focus{border:2px solid ", ";}@media (hover: hover){&:hover{border:2px solid ", ";}}"], ["", "", "", "", "", "", "", "", "", "", ""])), function (_a) {
    var $pictureSize = _a.$pictureSize, $hasPicture = _a.$hasPicture;
    return $pictureSize && $hasPicture ? PICTURE_SIZES[$pictureSize].width : 0;
}, function (_a) {
    var $pictureSize = _a.$pictureSize, $hasPicture = _a.$hasPicture;
    return ($pictureSize && $hasPicture ? polished_1.rem(16) : 0);
}, function (_a) {
    var theme = _a.theme;
    return theme.vui.component.buttonCard.default.backgroundColor;
}, function (_a) {
    var theme = _a.theme;
    return theme.vui.component.buttonCard.default.borderColor;
}, polished_1.rem(8), polished_1.rem(20), polished_1.rem(16), function (_a) {
    var theme = _a.theme, $selected = _a.$selected;
    return $selected && styled_components_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["border:2px solid ", ";"], ["", ""])), theme.vui.component.buttonCard.selected.borderColor);
}, function (_a) {
    var theme = _a.theme;
    return theme.vui.component.buttonCard.selected.borderColor;
}, function (_a) {
    var theme = _a.theme;
    return theme.vui.component.buttonCard.selected.borderColor;
});
exports.Title = styled_components_1.default(typography_1.Typography.Sub1).attrs(function () { return ({ as: 'div' }); }).withConfig({ displayName: "Title", componentId: "sc-fpbus0" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["color:", ";"], ["", ""])), function (_a) {
    var theme = _a.theme;
    return theme.vui.component.buttonCard.default.titleColor;
});
exports.Content = styled_components_1.default(typography_1.Typography.Body1).attrs(function () { return ({ as: 'div' }); }).withConfig({ displayName: "Content", componentId: "sc-iyx70r" })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["color:", ";"], ["", ""])), function (_a) {
    var theme = _a.theme;
    return theme.vui.component.buttonCard.default.contentColor;
});
exports.PictureContainer = styled_components_1.default.div.withConfig({ displayName: "PictureContainer", componentId: "sc-u988wf" })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["display:flex;height:", ";align-items:center;justify-content:center;overflow:hidden;border-radius:", ";"], ["", "", ""])), function (_a) {
    var $pictureSize = _a.$pictureSize;
    return PICTURE_SIZES[$pictureSize].height;
}, function (_a) {
    var $pictureType = _a.$pictureType;
    return ($pictureType === 'circle' ? '50%' : 'none');
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
