"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tabs = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var Styled = __importStar(require("./styles"));
exports.Tabs = react_1.memo(function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? 'fullWidth' : _b, value = _a.value, props = __rest(_a, ["variant", "value"]);
    var tabsRef = react_1.useRef(null);
    var observerRef = react_1.useRef(null);
    var _c = react_1.useState(0), indicatorWidth = _c[0], setIndicatorWidth = _c[1];
    react_1.useEffect(function () {
        var _a, _b;
        var selectedTabElement = (_a = tabsRef.current) === null || _a === void 0 ? void 0 : _a.querySelector('[aria-selected=true] .MuiTab-wrapper');
        setIndicatorWidth((_b = selectedTabElement === null || selectedTabElement === void 0 ? void 0 : selectedTabElement.scrollWidth) !== null && _b !== void 0 ? _b : 0);
        if (selectedTabElement) {
            observerRef.current = new ResizeObserver(function (_a) {
                var contentRect = _a[0].contentRect;
                setIndicatorWidth(contentRect.width);
            });
            observerRef.current.observe(selectedTabElement);
        }
        return function () { var _a; return (_a = observerRef.current) === null || _a === void 0 ? void 0 : _a.disconnect(); };
    }, [value]);
    var tabIndicatorProps = react_1.useMemo(function () { return ({
        children: indicatorWidth > 0 ? jsx_runtime_1.jsx(Styled.Indicator, { width: indicatorWidth }, void 0) : null,
    }); }, [indicatorWidth]);
    return (jsx_runtime_1.jsx(Styled.MeterialTabs, __assign({ ref: tabsRef, variant: variant }, props, { value: value, TabIndicatorProps: tabIndicatorProps }), void 0));
});
