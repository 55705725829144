"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarSolidIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var svg_icon_1 = require("../components/svg-icon");
exports.CalendarSolidIcon = react_1.forwardRef(function (props, ref) { return (jsx_runtime_1.jsx(svg_icon_1.SvgIcon, __assign({ ref: ref }, props, { viewBox: "0 0 20 20" }, { children: jsx_runtime_1.jsx("path", { d: "M6 5V1v4zm8 0V1v4zM5 9h10H5zM3 19h14a2 2 0 002-2V5a2 2 0 00-2-2H3a2 2 0 00-2 2v12a2 2 0 002 2z", stroke: "currentColor", strokeWidth: "1.333", strokeLinecap: "round", strokeLinejoin: "round" }, void 0) }), void 0)); });
