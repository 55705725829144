"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DoneIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var svg_icon_1 = require("../components/svg-icon");
exports.DoneIcon = react_1.forwardRef(function (props, ref) { return (jsx_runtime_1.jsxs(svg_icon_1.SvgIcon, __assign({ ref: ref }, props, { viewBox: "0 0 123 100" }, { children: [jsx_runtime_1.jsx("path", { d: "M60.831 100c19.882 0 36-16.118 36-36s-16.118-36-36-36-36 16.118-36 36 16.118 36 36 36z", fill: "#31AD6D" }, void 0), jsx_runtime_1.jsx("path", { d: "M49.831 66l7 7 16-18", stroke: "#fff", strokeWidth: "6", strokeLinecap: "round", strokeLinejoin: "round" }, void 0), jsx_runtime_1.jsx("rect", { opacity: ".4", y: "45.649", width: "3", height: "16", rx: "1.5", transform: "rotate(-72 0 45.65)", fill: "#31AD6D" }, void 0), jsx_runtime_1.jsx("rect", { opacity: ".4", x: "22.499", y: "13.104", width: "3", height: "16", rx: "1.5", transform: "rotate(-36 22.499 13.104)", fill: "#31AD6D" }, void 0), jsx_runtime_1.jsx("rect", { opacity: ".4", width: "3", height: "16", rx: "1.5", transform: "scale(-1 1) rotate(-72 -29.915 107.239)", fill: "#31AD6D" }, void 0), jsx_runtime_1.jsx("rect", { opacity: ".4", width: "3", height: "16", rx: "1.5", transform: "scale(-1 1) rotate(-36 -29.916 160.689)", fill: "#31AD6D" }, void 0), jsx_runtime_1.jsx("rect", { opacity: ".4", width: "3", height: "16", rx: "1.5", transform: "matrix(-1 0 0 1 62.833 0)", fill: "#31AD6D" }, void 0)] }), void 0)); });
