"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultVillageUiTheme = void 0;
var styles_1 = require("@material-ui/core/styles");
var button_theme_1 = require("./button-theme");
var tabs_theme_1 = require("./tabs-theme");
var colors_1 = require("./colors");
var chip_theme_1 = require("./chip-theme");
var spinner_theme_1 = require("./spinner-theme");
var input_theme_1 = require("./input-theme");
var button_card_theme_1 = require("./button-card-theme");
var typography_theme_1 = require("./typography-theme");
exports.defaultVillageUiTheme = styles_1.createTheme({
    vui: {
        colors: colors_1.colors,
        component: {
            button: button_theme_1.buttonTheme,
            chip: chip_theme_1.chipTheme,
            input: input_theme_1.inputTheme,
            spinner: spinner_theme_1.spinnerTheme,
            tab: tabs_theme_1.tabTheme,
            buttonCard: button_card_theme_1.buttonCardTheme,
        },
        typography: typography_theme_1.typographyTheme,
        platform: 'ios',
    },
    props: {
        MuiButton: {
            disableElevation: true,
        },
        MuiButtonBase: {
            disableRipple: true,
            disableTouchRipple: true,
        },
    },
});
