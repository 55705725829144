"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buttonCardTheme = void 0;
var colors_1 = require("./colors");
exports.buttonCardTheme = {
    default: {
        backgroundColor: colors_1.colors.general.white,
        titleColor: colors_1.colors.neutral.gray80,
        contentColor: colors_1.colors.neutral.gray80,
        borderColor: colors_1.colors.secondary.ghost,
    },
    selected: {
        backgroundColor: colors_1.colors.general.white,
        titleColor: colors_1.colors.neutral.gray80,
        contentColor: colors_1.colors.neutral.gray80,
        borderColor: colors_1.colors.secondary.veniceBlue,
    },
};
