"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShieldIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var svg_icon_1 = require("../components/svg-icon");
exports.ShieldIcon = react_1.forwardRef(function (props, ref) { return (jsx_runtime_1.jsx(svg_icon_1.SvgIcon, __assign({ ref: ref }, props, { viewBox: "0 0 24 24" }, { children: jsx_runtime_1.jsx("path", { d: "M19.4521 5.77634C19.4475 5.67984 19.4058 5.58908 19.3362 5.52357C19.2666 5.45807 19.1746 5.42306 19.0799 5.42608C17.609 5.45188 14.6997 5.20117 12.2349 3.08851C12.1695 3.03139 12.0861 3 12 3C11.9139 3 11.8305 3.03139 11.7651 3.08851C9.30031 5.20117 6.39101 5.45188 4.9201 5.42608C4.82544 5.42306 4.7334 5.45807 4.66379 5.52357C4.59417 5.58908 4.55254 5.67984 4.54785 5.77634C4.40691 9.48916 4.48641 14.1975 6.06936 16.4097C7.72459 18.7325 11.1905 20.705 12 21C12.8095 20.7014 16.2754 18.7288 17.9306 16.4097C19.5136 14.1975 19.5931 9.48916 19.4521 5.77634ZM15.1876 10.1528L11.5085 14.2749C11.458 14.3303 11.3971 14.3748 11.3294 14.4058C11.2618 14.4368 11.1887 14.4538 11.1146 14.4556H11.1073C11.035 14.4554 10.9634 14.4403 10.897 14.4111C10.8305 14.382 10.7705 14.3394 10.7206 14.286L8.82326 12.3097C8.72262 12.2046 8.66703 12.063 8.66872 11.9161C8.67042 11.7691 8.72926 11.6289 8.8323 11.5262C8.93533 11.4236 9.07413 11.3668 9.21816 11.3686C9.36218 11.3703 9.49964 11.4303 9.60028 11.5354L11.0929 13.0951L14.3853 9.40805C14.4825 9.3011 14.6171 9.23763 14.76 9.23145C14.9028 9.22526 15.0423 9.27686 15.148 9.37502C15.2538 9.47318 15.3172 9.60996 15.3246 9.75562C15.3321 9.90127 15.2828 10.044 15.1876 10.1528Z", fill: "#2E476B" }, void 0) }), void 0)); });
