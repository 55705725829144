"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulbIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var svg_icon_1 = require("../components/svg-icon");
exports.BulbIcon = react_1.forwardRef(function (props, ref) { return (jsx_runtime_1.jsx(svg_icon_1.SvgIcon, __assign({ ref: ref }, props, { viewBox: "0 0 24 24" }, { children: jsx_runtime_1.jsx("path", { d: "M20.018 11.973a.3.3 0 01.3-.3H22.5a.3.3 0 01.3.3v1.254a.3.3 0 01-.3.3h-2.182a.3.3 0 01-.3-.3v-1.254zm-17.618 0a.3.3 0 01.3-.3h2.182a.3.3 0 01.3.3v1.254a.3.3 0 01-.3.3H2.7a.3.3 0 01-.3-.3v-1.254zM13.227 2.4a.3.3 0 01.3.3v2.182a.3.3 0 01-.3.3h-1.254a.3.3 0 01-.3-.3V2.7a.3.3 0 01.3-.3h1.254zM5.822 4.932a.3.3 0 01.426 0l1.55 1.558a.3.3 0 01-.001.424l-.892.886a.3.3 0 01-.423 0L4.93 6.256a.3.3 0 010-.424l.892-.9zm11.58 1.973a.3.3 0 01-.001-.424l1.543-1.55a.3.3 0 01.424 0l.901.9a.3.3 0 010 .425L18.719 7.8a.3.3 0 01-.424 0l-.894-.894zm-4.802.131a5.564 5.564 0 015.563 5.564 5.576 5.576 0 01-2.781 4.822v1.669a.927.927 0 01-.928.927h-3.709a.927.927 0 01-.927-.927v-1.67A5.576 5.576 0 017.036 12.6 5.564 5.564 0 0112.6 7.036zm1.554 13.91a.3.3 0 01.3.3v.627a.927.927 0 01-.927.927h-1.854a.927.927 0 01-.928-.927v-.628a.3.3 0 01.3-.3h3.11z", fill: "currentColor" }, void 0) }), void 0)); });
