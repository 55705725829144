"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhoneIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var svg_icon_1 = require("../components/svg-icon");
exports.PhoneIcon = react_1.forwardRef(function (props, ref) { return (jsx_runtime_1.jsx(svg_icon_1.SvgIcon, __assign({ ref: ref }, props, { viewBox: "0 0 24 24" }, { children: jsx_runtime_1.jsx("path", { d: "M19.14 15.6122V17.7415C19.1408 17.9396 19.1002 18.1356 19.0209 18.3171C18.9415 18.4986 18.8251 18.6615 18.6792 18.7954C18.5333 18.9293 18.361 19.0313 18.1734 19.0947C17.9857 19.1582 17.7869 19.1818 17.5897 19.164C15.4016 18.9262 13.2998 18.1783 11.4532 16.9807C9.73496 15.8889 8.27821 14.4321 7.18642 12.7139C5.9853 10.8584 5.23816 8.74603 5.00553 6.54796C4.98816 6.3515 5.01182 6.15359 5.07503 5.96677C5.13823 5.77996 5.23959 5.60832 5.37266 5.46277C5.50574 5.31722 5.66763 5.20093 5.84805 5.12128C6.02847 5.04164 6.22348 5.00038 6.42069 5.00012H8.54999C8.8954 4.99562 9.23065 5.11696 9.49317 5.3415C9.75568 5.56604 9.92752 5.87843 9.97662 6.22037C10.0667 6.90311 10.2337 7.57346 10.4745 8.21863C10.5701 8.47317 10.5908 8.74976 10.534 9.01567C10.4773 9.28157 10.3455 9.52564 10.1543 9.71897L9.25348 10.6198C10.2658 12.4002 11.7399 13.8743 13.5203 14.8866L14.4211 13.9858C14.6144 13.7946 14.8585 13.6628 15.1244 13.6061C15.3903 13.5493 15.6669 13.57 15.9214 13.6655C16.5666 13.9063 17.237 14.0734 17.9197 14.1635C18.2651 14.2124 18.5805 14.3864 18.8059 14.6526C19.0313 14.9188 19.1511 15.2586 19.1424 15.6073L19.14 15.6122Z", fill: "currentColor", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }, void 0) }), void 0)); });
