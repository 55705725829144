import { Button, Input } from '@village/ui';
import { useState, VFC } from 'react';
import { Box, useTheme } from '@material-ui/core';

import * as Primitives from 'components/primitives';
import { useAuthState } from 'hooks';

const ProfilePageContent: VFC = () => {
    const theme = useTheme();
    const { error, authUser } = useAuthState();
    const [firstName, setFirstName] = useState<string>(authUser['custom:firstName']);
    const [lastName, setLastName] = useState<string>(authUser['custom:lastName']);
    const [email, setEmail] = useState<string>(authUser?.email);
    const [phone, setPhone] = useState<string>(authUser['custom:phoneNumber']);

    return (
        <Box display="flex" flexDirection="column" height="100%" bgcolor={theme.colors.uncategorized.whiteCatskill} pt={2} data-test="login-page">
            <Primitives.CardRounded>
                    <Box pb={2}>
                        <Input
                            label="First Name"
                            error={!!error}
                            fullWidth
                            type="email"
                            value={firstName}
                            onChange={(event) => setFirstName(event.target.value)}
                            data-test="firstName"
                        />
                    </Box>
                    <Box pb={2}>
                        <Input
                            label="Last Name"
                            error={!!error}
                            fullWidth
                            type="text"
                            value={lastName}
                            onChange={(event) => setLastName(event.target.value)}
                            data-test="lastName"
                        />
                    </Box>
                    <Box pb={2}>
                        <Input
                            label="Phone Number"
                            error={!!error}
                            fullWidth
                            type="tel"
                            value={phone}
                            onChange={(event) => setPhone(event.target.value)}
                            data-test="phone"
                        />
                    </Box>
                    <Box pb={2}>
                        <Input
                            label="Email address"
                            error={!!error}
                            fullWidth
                            type="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            data-test="email"
                        />
                    </Box>
                    <Box>
                        <Button onClick={() => console.log('Save to cognito function')} type="submit" fullWidth={true} data-test="submit-signin">
                            Save
                        </Button>
                    </Box>
            </Primitives.CardRounded>
        </Box>
    );
};

export { ProfilePageContent };
