"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var svg_icon_1 = require("../components/svg-icon");
exports.LocationIcon = react_1.forwardRef(function (props, ref) { return (jsx_runtime_1.jsxs(svg_icon_1.SvgIcon, __assign({ ref: ref }, props, { viewBox: "0 0 24 20" }, { children: [jsx_runtime_1.jsx("path", { d: "M17.439 8.63648C17.439 13.409 11.3025 17.5 11.3025 17.5C11.3025 17.5 5.16602 13.409 5.16602 8.63648C5.16602 7.00898 5.81254 5.44814 6.96335 4.29733C8.11416 3.14652 9.675 2.5 11.3025 2.5C12.93 2.5 14.4908 3.14652 15.6416 4.29733C16.7924 5.44814 17.439 7.00898 17.439 8.63648Z", fill: "currentColor", stroke: "currentColor", strokeWidth: "1.4", strokeLinecap: "round", strokeLinejoin: "round" }, void 0), jsx_runtime_1.jsx("path", { d: "M11.3014 10.6818C12.431 10.6818 13.3468 9.76601 13.3468 8.63631C13.3468 7.50662 12.431 6.59082 11.3014 6.59082C10.1717 6.59082 9.25586 7.50662 9.25586 8.63631C9.25586 9.76601 10.1717 10.6818 11.3014 10.6818Z", fill: "white" }, void 0)] }), void 0)); });
