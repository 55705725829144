import { createGlobalStyle } from 'styled-components/macro';

const FontFamilyStyle = createGlobalStyle`
    html, body, #root {
        background-color: ${({ theme }) => theme.colors.general.white};
        overflow: unset;
        height: 100%;
    }

    html {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    }

    body {
        overscroll-behavior-y: none;
    }
`;

export { FontFamilyStyle };
