import * as Sentry from '@sentry/react';
import { appConfig } from './app-config';

const initSentry = (): void => {
    Sentry.init({
        dsn: appConfig.sentryDsn,
        environment: appConfig.environment,
        integrations: [
            new Sentry.Integrations.UserAgent(),
            new Sentry.Integrations.FunctionToString(),
            new Sentry.Integrations.GlobalHandlers(),
            new Sentry.Integrations.Breadcrumbs(),
        ],
    });
};

export { initSentry };
