import Amplify from 'aws-amplify';

import { Environment } from 'enums';
import { CachedCognitoAuth, CognitoConfig } from 'types';
import { appConfig, setSessionStorage } from 'utilities';

import { alphaAWSConfig } from './aws-config-alpha';
import { uatAWSConfig } from './aws-config-uat';
import { prodAWSConfig } from './aws-config-prod';

const getAmplifyConfig = (): CognitoConfig => {
    switch (appConfig.environment) {
        default:
        case Environment.DEVELOPMENT:
        case Environment.TEST: // 4/28/21 - There is not currently a TEST user pool in AWS
        case Environment.ALPHA:
            return alphaAWSConfig;
        case Environment.UAT:
            return uatAWSConfig;
        case Environment.PRODUCTION:
            return prodAWSConfig;
    }
};

const cacheCognitoTokens = (
    cachedAuth: CachedCognitoAuth,
    config: CognitoConfig
): void => {
    const { lastAuthUser, idToken, accessToken, refreshToken } = cachedAuth;

    if (!lastAuthUser || !idToken || !accessToken || !refreshToken) return;

    const keyPrefix = `CognitoIdentityServiceProvider.${config.aws_user_pools_web_client_id}`;

    setSessionStorage(`${keyPrefix}.${lastAuthUser}.idToken`, idToken);
    setSessionStorage(`${keyPrefix}.${lastAuthUser}.accessToken`, accessToken);
    setSessionStorage(
        `${keyPrefix}.${lastAuthUser}.refreshToken`,
        refreshToken
    );
    setSessionStorage(`${keyPrefix}.LastAuthUser`, lastAuthUser);
};

const initCognito = (): void => {
    Amplify.configure(getAmplifyConfig());
};

export { cacheCognitoTokens, initCognito, getAmplifyConfig };
