"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Input = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var Styled = __importStar(require("./styles"));
var utils_1 = require("./utils");
exports.Input = react_1.memo(react_1.forwardRef(function (_a, ref) {
    var label = _a.label, error = _a.error, helperText = _a.helperText, fullWidth = _a.fullWidth, props = __rest(_a, ["label", "error", "helperText", "fullWidth"]);
    var hasError = Boolean(error);
    var status = react_1.useMemo(function () { return utils_1.getStatus({ disabled: props.disabled, error: error, readOnly: props.readOnly }); }, [props.disabled, error, props.readOnly]);
    var inputProps = react_1.useMemo(function () {
        if (props.type === 'textarea') {
            // rows is not supported by MUI, we use minRows instead.
            var rows = props.rows, textareaProps = __rest(props, ["rows"]);
            return textareaProps;
        }
        // endAdornment & startAdornment are not inputProps.
        var endAdornment = props.endAdornment, startAdornment = props.startAdornment, other = __rest(props, ["endAdornment", "startAdornment"]);
        return other;
    }, [props]);
    return (jsx_runtime_1.jsxs(Styled.InputContainer, { children: [label ? jsx_runtime_1.jsx(Styled.Label, { children: label }, void 0) : null, jsx_runtime_1.jsx(Styled.BaseInput, { "$status": status, error: hasError, fullWidth: fullWidth, multiline: props.type === 'textarea', type: props.type === 'textarea' ? 'text' : props.type, startAdornment: props.type === 'textarea' ? undefined : props.startAdornment, endAdornment: props.type === 'textarea' ? undefined : props.endAdornment, disabled: props.disabled, inputRef: ref, minRows: props.type === 'textarea' ? props.rows : undefined, inputProps: inputProps }, void 0), helperText || hasError ? (jsx_runtime_1.jsx(Styled.HelperText, __assign({ "$error": hasError }, { children: error !== null && error !== void 0 ? error : helperText }), void 0)) : null] }, void 0));
}));
