"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledChevronRight = exports.ItemPicture = exports.ItemIcon = exports.ItemContent = exports.ItemContainer = exports.Divider = exports.ItemsContainer = exports.ListHeader = exports.ListContainer = exports.ICON_SIZE = exports.ICON_PICTURE_MARGIN_RIGHT = exports.CONTENT_PADDING_RIGHT = exports.ITEM_PADDING = void 0;
var icons_1 = require("@village/icons");
var polished_1 = require("polished");
var styled_components_1 = __importStar(require("styled-components"));
var typography_1 = require("../typography");
var util_1 = require("./util");
exports.ITEM_PADDING = polished_1.rem(16);
exports.CONTENT_PADDING_RIGHT = polished_1.rem(16);
exports.ICON_PICTURE_MARGIN_RIGHT = polished_1.rem(16);
exports.ICON_SIZE = polished_1.rem(40);
exports.ListContainer = styled_components_1.default.div.withConfig({ displayName: "ListContainer", componentId: "sc-1h44dif" })(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
exports.ListHeader = styled_components_1.default(typography_1.Typography.TitleCaps).attrs(function () { return ({ as: 'div' }); }).withConfig({ displayName: "ListHeader", componentId: "sc-nx8w1x" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["", ";padding:", " ", ";color:", ";background-color:", ";"], ["", "", "", "", "", ""])), function (_a) {
    var $position = _a.$position;
    return $position === 'sticky' && styled_components_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["position:sticky;top:0;z-index:1;"], [""])));
}, polished_1.rem(14), polished_1.rem(16), function (_a) {
    var theme = _a.theme;
    return theme.vui.colors.neutral.gray80;
}, function (_a) {
    var theme = _a.theme;
    return theme.vui.colors.neutral.gray25;
});
exports.ItemsContainer = styled_components_1.default.div.withConfig({ displayName: "ItemsContainer", componentId: "sc-q8rp5z" })(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
exports.Divider = styled_components_1.default.hr.withConfig({ displayName: "Divider", componentId: "sc-1u9xcnv" })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["position:absolute;bottom:-0.5px;right:0;width:", ";height:0.5px;margin:0;border:none;background-color:", ";opacity:1;transition:opacity 300ms;"], ["", "", ""])), function (_a) {
    var $hasIcon = _a.$hasIcon;
    return util_1.getDividerCssWidth($hasIcon);
}, function (_a) {
    var theme = _a.theme;
    return theme.vui.colors.secondary.athensGray;
});
exports.ItemContainer = styled_components_1.default.div.withConfig({ displayName: "ItemContainer", componentId: "sc-fx0613" })(templateObject_7 || (templateObject_7 = __makeTemplateObject(["position:relative;display:flex;align-items:center;padding:", ";", ";"], ["", "", ""])), exports.ITEM_PADDING, function (_a) {
    var $isInteractive = _a.$isInteractive;
    return $isInteractive && styled_components_1.css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["&:active{background-color:", ";", "{opacity:0;}}transition:background-color 300ms;"], ["", "", ""])), function (_a) {
        var theme = _a.theme;
        return theme.vui.colors.neutral.gray10;
    }, exports.Divider);
});
exports.ItemContent = styled_components_1.default(typography_1.Typography.Body1).attrs(function () { return ({ as: 'div' }); }).withConfig({ displayName: "ItemContent", componentId: "sc-76xj9f" })(templateObject_8 || (templateObject_8 = __makeTemplateObject(["display:flex;align-items:center;flex-wrap:wrap;flex:1;color:", ";"], ["", ""])), function (_a) {
    var theme = _a.theme;
    return theme.vui.colors.neutral.gray80;
});
exports.ItemIcon = styled_components_1.default.div.withConfig({ displayName: "ItemIcon", componentId: "sc-b3fmv2" })(templateObject_9 || (templateObject_9 = __makeTemplateObject(["display:flex;align-items:center;justify-content:center;width:", ";height:", ";margin-right:", ";border-radius:50%;overflow:hidden;> *{flex:1;}"], ["", "", "", ""])), exports.ICON_SIZE, exports.ICON_SIZE, exports.ICON_PICTURE_MARGIN_RIGHT);
exports.ItemPicture = styled_components_1.default.div.withConfig({ displayName: "ItemPicture", componentId: "sc-1uw059r" })(templateObject_10 || (templateObject_10 = __makeTemplateObject(["display:flex;width:", ";height:", ";margin-right:", ";align-items:center;justify-content:center;overflow:hidden;"], ["", "", "", ""])), polished_1.rem(90), polished_1.rem(56), exports.ICON_PICTURE_MARGIN_RIGHT);
exports.StyledChevronRight = styled_components_1.default(icons_1.ChevronRightIcon).attrs(function () { return ({ size: 1 }); }).withConfig({ displayName: "StyledChevronRight", componentId: "sc-wzv1rj" })(templateObject_11 || (templateObject_11 = __makeTemplateObject(["color:", ";margin-left:", ";"], ["", "", ""])), function (_a) {
    var theme = _a.theme;
    return theme.vui.colors.neutral.gray40;
}, exports.CONTENT_PADDING_RIGHT);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
