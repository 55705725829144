"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClinicIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var svg_icon_1 = require("../components/svg-icon");
exports.ClinicIcon = react_1.forwardRef(function (props, ref) { return (jsx_runtime_1.jsx(svg_icon_1.SvgIcon, __assign({ ref: ref }, props, { viewBox: "0 0 24 24" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 3.99765L6.28571 9.7355V20H17.7143V9.7355L12 3.99765ZM3.81791 12.2135L4 12.0306V20C4 21.1046 5.02335 22 6.28571 22H17.7143C18.9767 22 20 21.1046 20 20V12.0306L20.1821 12.2135C20.5813 12.6143 21.2475 12.6323 21.6701 12.2538C22.0928 11.8753 22.1118 11.2435 21.7126 10.8426L13.5283 2.62451C12.6944 1.78724 11.2972 1.79567 10.4694 2.62681L2.28736 10.8426C1.88819 11.2435 1.90722 11.8753 2.32987 12.2538C2.75252 12.6323 3.41874 12.6143 3.81791 12.2135ZM11 13V10H13V13H16V15H13V18H11V15H8V13H11Z", fill: "currentColor" }, void 0) }), void 0)); });
