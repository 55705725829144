import { GlobalStyles } from '@village/ui';
import { VFC } from 'react';
import { StylesProvider } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Router } from 'react-router-dom';

import { FontFamilyStyle, AuthProvider } from 'components';
import { theme } from 'theme';
import { history } from 'routes';
import { FeatureFlagsProvider } from './context';
import { LayoutProvider } from './context/layout/layout-context';
import { MainRouter } from 'routes/MainRouter';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            useErrorBoundary: true,
            staleTime: 900000, // 15 min in milliseconds, after data is considered stale
        },
    },
});

const App: VFC = () => (
    <FeatureFlagsProvider>
        <QueryClientProvider client={queryClient}>
            <StylesProvider injectFirst>
                <MuiThemeProvider theme={theme}>
                    <ThemeProvider theme={theme}>
                        <GlobalStyles />
                        <FontFamilyStyle />
                        <AuthProvider>
                            <Router history={history}>
                                <LayoutProvider>
                                    <MainRouter />
                                </LayoutProvider>
                            </Router>
                        </AuthProvider>
                    </ThemeProvider>
                </MuiThemeProvider>
            </StylesProvider>
        </QueryClientProvider>
    </FeatureFlagsProvider>
);

export { App };
