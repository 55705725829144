"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TitleCaps = exports.Sub2 = exports.Sub1 = exports.H4 = exports.H3 = exports.H2 = exports.H1 = exports.Caption = exports.Body2 = exports.Body1 = void 0;
var styled_components_1 = __importStar(require("styled-components"));
var baseStyles = function (tagProps, tagName) { return styled_components_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["margin:0;font-size:", ";font-weight:", ";line-height:", ";text-transform:", ";"], ["", "", "", "", ""])), function (_a) {
    var theme = _a.theme;
    return theme.vui.typography[theme.vui.platform][tagName].fontSize;
}, function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = tagProps.$fontWeight) !== null && _b !== void 0 ? _b : theme.vui.typography[theme.vui.platform][tagName].fontWeight;
}, function (_a) {
    var theme = _a.theme;
    return theme.vui.typography[theme.vui.platform][tagName].lineHeight;
}, tagProps.$uppercase ? 'uppercase' : null); };
exports.Body1 = styled_components_1.default.p.withConfig({ displayName: "Body1", componentId: "sc-1c7elmx" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["", ";"], ["", ""])), function (props) { return baseStyles(props, 'body1'); });
exports.Body2 = styled_components_1.default.p.withConfig({ displayName: "Body2", componentId: "sc-1h3wsmw" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["", ";"], ["", ""])), function (props) { return baseStyles(props, 'body2'); });
exports.Caption = styled_components_1.default.p.withConfig({ displayName: "Caption", componentId: "sc-1vmv86b" })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["", ";"], ["", ""])), function (props) { return baseStyles(props, 'caption'); });
exports.H1 = styled_components_1.default.h1.withConfig({ displayName: "H1", componentId: "sc-hv7h3v" })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["", ";"], ["", ""])), function (props) { return baseStyles(props, 'h1'); });
exports.H2 = styled_components_1.default.h2.withConfig({ displayName: "H2", componentId: "sc-utftfl" })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["", ";"], ["", ""])), function (props) { return baseStyles(props, 'h2'); });
exports.H3 = styled_components_1.default.h3.withConfig({ displayName: "H3", componentId: "sc-xx1cua" })(templateObject_7 || (templateObject_7 = __makeTemplateObject(["", ";"], ["", ""])), function (props) { return baseStyles(props, 'h3'); });
exports.H4 = styled_components_1.default.h4.withConfig({ displayName: "H4", componentId: "sc-oufh90" })(templateObject_8 || (templateObject_8 = __makeTemplateObject(["", ";"], ["", ""])), function (props) { return baseStyles(props, 'h4'); });
exports.Sub1 = styled_components_1.default.p.withConfig({ displayName: "Sub1", componentId: "sc-soaer1" })(templateObject_9 || (templateObject_9 = __makeTemplateObject(["", ";"], ["", ""])), function (props) { return baseStyles(props, 'sub1'); });
exports.Sub2 = styled_components_1.default.p.withConfig({ displayName: "Sub2", componentId: "sc-1uv1j0z" })(templateObject_10 || (templateObject_10 = __makeTemplateObject(["", ";"], ["", ""])), function (props) { return baseStyles(props, 'sub2'); });
exports.TitleCaps = styled_components_1.default.p.withConfig({ displayName: "TitleCaps", componentId: "sc-4qk18s" })(templateObject_11 || (templateObject_11 = __makeTemplateObject(["", ";text-transform:uppercase;"], ["", ""])), function (props) { return baseStyles(props, 'titleCaps'); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
