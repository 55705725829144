import type { FC } from 'react';
import { memo } from 'react';

import { LoginPageContent } from './login-page';

import { Page } from 'pages/layouts';

const placeholders = {
    main: <LoginPageContent />,
};

const LoginPage: FC = memo(() => <Page placeholders={placeholders} title="Login" />);

export { LoginPage };
