import type { CognitoConfig } from 'types';

const alphaAWSConfig: CognitoConfig = {
    aws_project_region: 'us-east-2',
    aws_cognito_identity_pool_id: 'us-east-2:a4522a9d-c41a-4728-b10e-a59712d62831',
    aws_cognito_region: 'us-east-2',
    aws_user_pools_id: 'us-east-2_eLaa4zzra',
    aws_user_pools_web_client_id: '54ahtujd0kutkic6urqomjg83n',
    storage: sessionStorage,
};

export { alphaAWSConfig };
