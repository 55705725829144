import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import type { CognitoUser } from 'amazon-cognito-identity-js';

import type { AuthState } from 'types';

import { AuthActionsContext, AuthStateContext } from './auth-context';
import { AuthManager } from 'utilities/AuthManager';

const defaultState: AuthState = {
    authUser: null,
    // At first we assume the app will try to auto-login by default, if it failed then we show the login form.
    // 1. case of webview the native will send `autoSignIn` event to rigger the sign-in.
    // 2. in case we still have a valid session
    isLoggingIn: true,
    isLoggedIn: false,
    isLoggingOut: false,
    error: undefined,
};

const AuthProvider: FC = ({ children }) => {
    const queryClient = useQueryClient();
    const [authState, setAuthState] = useState<AuthState>(defaultState);

    const login = useCallback(async (username: string, password: string) => {
        try {
            setAuthState((state) => ({ ...state, isLoggingIn: true }));
            const authUser = await AuthManager.login(username, password);
            setAuthState(() => ({
                ...defaultState,
                isLoggedIn: true,
                isLoggingIn: false,
                authUser,
            }));
        } catch (e) {
            setAuthState(() => ({
                ...defaultState,
                isLoggingIn: false,
                error: 'Could not authenticate user',
            }));
        }
    }, []);

    const logout = useCallback(async () => {
        await queryClient.invalidateQueries();
        setAuthState({
            ...defaultState,
            isLoggingOut: true,
            isLoggingIn: false,
        });
        await AuthManager.logout();
    }, []);

    const onAutoLoginSuccess = useCallback((user: CognitoUser) => {
        setAuthState(() => ({
            ...defaultState,
            isLoggingIn: false,
            isLoggedIn: true,
            authUser: user,
        }));
    }, []);

    const onAutoLoginFailure = useCallback(() => {
        setAuthState(() => ({
            ...defaultState,
            isLoggingIn: false,
        }));
    }, []);

    const authActions = useMemo(() => ({ login, logout }), [login, logout]);

    useEffect(() => {
        AuthManager.currentAuthenticatedUser()
            .then(onAutoLoginSuccess)
            .catch(onAutoLoginFailure);
    }, [onAutoLoginSuccess, onAutoLoginFailure]);

    return (
        <AuthStateContext.Provider value={authState}>
            <AuthActionsContext.Provider value={authActions}>
                {children}
            </AuthActionsContext.Provider>
        </AuthStateContext.Provider>
    );
};

export { AuthProvider };
