import { bookingRouteConfigs } from 'consts';
import { useAuthState } from 'hooks';
import { FunctionComponent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { RouteConfig } from 'types';

export const RouterManager: FunctionComponent<{ children: JSX.Element }> = ({
    children,
}) => {
    const { isLoggedIn, isLoggingIn } = useAuthState();
    const history = useHistory();

    useEffect(() => {
        if (
            !isLoggedIn &&
            !isLoggingIn &&
            (bookingRouteConfigs[history.location.pathname] as RouteConfig)?.privateRoute
        ) {
            history.push(
                `/?redirectTo=${window.location.pathname}${encodeURIComponent(
                    window.location.search
                )}`
            );
        } else if (
            isLoggedIn &&
            !isLoggingIn &&
            !(bookingRouteConfigs[history.location.pathname] as RouteConfig)?.privateRoute
        ) {
            history.push('/dashboard');
        }
    }, [isLoggedIn, history, isLoggingIn]);

    return children;
};
