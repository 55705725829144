import { FC } from 'react';

import * as Styled from './styles';
import { appConfig, eventCheck } from 'utilities';

interface BottomDrawerProps {
    onToggle: (value: boolean) => void;
    open: boolean;
}

const { platform } = appConfig;

const BottomDrawer: FC<BottomDrawerProps> = ({ onToggle, open, children, ...rest }) => {
    return (
        <Styled.StyledSwipeableDrawer
            {...rest}
            anchor="bottom"
            disableBackdropTransition={platform !== 'ios'}
            disableDiscovery={platform === 'ios'}
            disableSwipeToOpen
            onClose={(event) => eventCheck(event) && onToggle(false)}
            onOpen={(event) => eventCheck(event) && onToggle(true)}
            open={open}
            PaperProps={{ square: false }}
        >
            {children}
        </Styled.StyledSwipeableDrawer>
    );
};

export { BottomDrawer };
export type { BottomDrawerProps };
