"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChevronDownIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var svg_icon_1 = require("../components/svg-icon");
exports.ChevronDownIcon = react_1.forwardRef(function (props, ref) { return (jsx_runtime_1.jsx(svg_icon_1.SvgIcon, __assign({ ref: ref }, props, { viewBox: "0 0 16 16" }, { children: jsx_runtime_1.jsx("path", { d: "M14.53 5.53a.75.75 0 00-1.06-1.06l1.06 1.06zM8 11l-.53.53a.75.75 0 001.06 0L8 11zM2.53 4.47a.75.75 0 00-1.06 1.06l1.06-1.06zm10.94 0l-6 6 1.06 1.06 6-6-1.06-1.06zm-4.94 6l-6-6-1.06 1.06 6 6 1.06-1.06z", fill: "currentColor" }, void 0) }), void 0)); });
