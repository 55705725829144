"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SvgIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var Styled = __importStar(require("./styles"));
var DEFAULT_SIZE_REM = 1.5;
exports.SvgIcon = react_1.forwardRef(function (_a, ref) {
    var _b, _c;
    var size = _a.size, height = _a.height, width = _a.width, children = _a.children, fullWidth = _a.fullWidth, rest = __rest(_a, ["size", "height", "width", "children", "fullWidth"]);
    var heightSpacing = (_b = height !== null && height !== void 0 ? height : size) !== null && _b !== void 0 ? _b : DEFAULT_SIZE_REM;
    var widthSpacing = (_c = width !== null && width !== void 0 ? width : size) !== null && _c !== void 0 ? _c : DEFAULT_SIZE_REM;
    var iconProps = react_1.useMemo(function () { return (__assign({ xmlns: 'http://www.w3.org/2000/svg', fill: 'none' }, rest)); }, [rest]);
    return (jsx_runtime_1.jsx(Styled.BaseSvg
    // Styled-component types issue: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/28884
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    , __assign({ 
        // Styled-component types issue: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/28884
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ref: ref, "$height": fullWidth ? '100%' : heightSpacing + "rem", "$width": fullWidth ? '100%' : widthSpacing + "rem" }, iconProps, { children: children }), void 0));
});
