import type { AppConfig } from 'types';
import { Environment } from 'enums';

export const getPlatform = (): AppConfig['platform'] => {
    const iOS = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);
    const android = navigator.userAgent.includes('wv');   
    return android ? 'android' : iOS ? 'ios' : 'web';
};

const getAppConfig = (): AppConfig => {
    const config = {
        countlyAppKey: '4f3bfe0937265971d6cb0f72acb2c24ebb52a8ce',
        countlyUrl: process.env.REACT_APP_COUNTLY_URL,
        environment: process.env.REACT_APP_ENV ?? Environment.DEVELOPMENT,
        platform: getPlatform(),
        schedulerUrl: process.env.REACT_APP_SCHEDULER_URL,
        sentryDsn: process.env.REACT_APP_SENTRY_DSN,
        careGoalUrl: process.env.REACT_APP_CARE_GOAL_URL,
        identityServiceUrl: process.env.REACT_APP_IDENTITY_SERVICE_URL,
    } as AppConfig;

    if (!config.sentryDsn || !config.countlyAppKey || !config.countlyUrl) {
        const errorMessage = 'appConfig() is missing required values';

        if (config.environment === Environment.DEVELOPMENT) {
            console.error(errorMessage, config);
        } else {
            throw new Error(errorMessage);
        }
    }

    return config;
};

const appConfig = getAppConfig();

export { appConfig, getAppConfig };
