"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buttonTheme = void 0;
var polished_1 = require("polished");
var colors_1 = require("./colors");
var utils_1 = require("./utils");
exports.buttonTheme = {
    size: {
        large: {
            borderRadius: '8px',
            fontSize: polished_1.rem(18),
            lineHeight: '1.25',
            padding: polished_1.rem(16),
        },
        medium: {
            borderRadius: '8px',
            fontSize: polished_1.rem(18),
            lineHeight: '1.6',
            padding: polished_1.rem(9) + " " + polished_1.rem(16),
        },
        small: {
            borderRadius: '4px',
            fontSize: polished_1.rem(14),
            lineHeight: '1.7',
            padding: polished_1.rem(5.5) + " " + polished_1.rem(16),
        },
    },
    variant: {
        destructive: {
            default: {
                background: 'transparent',
                border: colors_1.colors.feedback.redError,
                font: colors_1.colors.feedback.redError,
            },
            disabled: {
                background: 'transparent',
                border: colors_1.colors.feedback.redError,
                font: colors_1.colors.feedback.redError,
            },
            hover: {
                background: polished_1.rgba(colors_1.colors.feedback.redError, 0.15),
                border: colors_1.colors.feedback.redError,
                font: colors_1.colors.feedback.redError,
            },
            pressed: {
                background: polished_1.rgba(colors_1.colors.feedback.redError, 0.3),
                border: colors_1.colors.feedback.redError,
                font: colors_1.colors.feedback.redError,
            },
        },
        link: {
            default: {
                background: 'transparent',
                border: 'transparent',
                font: colors_1.colors.primary.primaryBlue,
            },
            disabled: {
                background: 'transparent',
                border: 'transparent',
                font: colors_1.colors.neutral.gray40,
            },
            hover: {
                background: colors_1.colors.neutral.gray5,
                border: 'transparent',
                font: colors_1.colors.primary.primaryBlue,
            },
            pressed: {
                background: colors_1.colors.neutral.gray10,
                border: 'transparent',
                font: colors_1.colors.primary.primaryBlue,
            },
        },
        primary: {
            default: {
                background: colors_1.colors.primary.primaryBlue,
                border: 'transparent',
                font: colors_1.colors.general.white,
            },
            disabled: {
                background: colors_1.colors.neutral.gray5,
                border: 'transparent',
                font: colors_1.colors.neutral.gray30,
            },
            hover: {
                background: colors_1.colors.secondary.blueHighlight,
                border: 'transparent',
                font: colors_1.colors.general.white,
            },
            pressed: {
                background: colors_1.colors.secondary.veniceBlue,
                border: 'transparent',
                font: colors_1.colors.general.white,
            },
        },
        tertiary: {
            default: {
                background: colors_1.colors.neutral.gray5,
                border: 'transparent',
                font: colors_1.colors.primary.primaryBlue,
            },
            disabled: {
                background: colors_1.colors.neutral.gray5,
                border: 'transparent',
                font: colors_1.colors.neutral.gray30,
            },
            hover: {
                background: utils_1.blendColors(colors_1.colors.neutral.gray5, polished_1.rgba(colors_1.colors.secondary.blueHighlight, 0.15)),
                border: 'transparent',
                font: colors_1.colors.primary.primaryBlue,
            },
            pressed: {
                background: utils_1.blendColors(colors_1.colors.neutral.gray5, polished_1.rgba(colors_1.colors.secondary.blueHighlight, 0.3)),
                border: 'transparent',
                font: colors_1.colors.primary.primaryBlue,
            },
        },
        secondary: {
            default: {
                background: 'transparent',
                border: colors_1.colors.primary.primaryBlue,
                font: colors_1.colors.primary.primaryBlue,
            },
            disabled: {
                background: 'transparent',
                border: colors_1.colors.neutral.gray10,
                font: colors_1.colors.neutral.gray40,
            },
            hover: {
                background: polished_1.rgba(colors_1.colors.secondary.blueHighlight, 0.15),
                border: colors_1.colors.primary.primaryBlue,
                font: colors_1.colors.primary.primaryBlue,
            },
            pressed: {
                background: polished_1.rgba(colors_1.colors.secondary.blueHighlight, 0.3),
                border: colors_1.colors.primary.primaryBlue,
                font: colors_1.colors.primary.primaryBlue,
            },
        },
    },
};
