import { createTheme } from '@material-ui/core/styles';
import { defaultVillageUiTheme } from '@village/ui';
import type { Colors } from 'types';

// https://www.figma.com/file/ac1K945HFTioYu1b65VZuh/Design-System?node-id=199%3A19
const colors: Colors = {
    ...defaultVillageUiTheme.vui.colors,
    neutral: {
        ...defaultVillageUiTheme.vui.colors.neutral,
        gray70: '#4C535C',
        bgBlue30: '#F2F8F9',
    },
    // https://chir.ag/projects/name-that-color/
    uncategorized: {
        ...defaultVillageUiTheme.vui.colors.secondary,
        athensGray: '#E3E7EB',
        chathamsBlue: '#19488A',
        dodgerBlue: '#56A5FF',
        mystic: '#DEEAEC',
        totemPole: '#920606',
        vanillaIce: '#F3D9DE',
        seashellPeach: '#FFF7F0',
        chipGreen: '#31ad6d1a',
    },
};

const theme = createTheme({
    ...defaultVillageUiTheme,
    overrides: {
        MuiListItem: {
            root: {
                '&$selected': {
                    backgroundColor: colors.uncategorized.athensGray,
                    '&:hover': {
                        backgroundColor: colors.uncategorized.athensGray,
                    },
                },
            },
        },
    },
    spacing: (factor: number) => `${0.5 * factor}rem`,
    colors,
    palette: {
        primary: {
            main: colors.primary.blue,
        },
        secondary: {
            main: colors.primary.turquoise,
        },
        tertiary: {
            main: colors.primary.navy,
        },
        error: {
            main: colors.feedback.redError,
        },
        success: {
            main: colors.feedback.greenSuccess,
        },
    },
    typography: {
        fontSize: 16,
        // https://www.figma.com/file/ac1K945HFTioYu1b65VZuh/Design-System?node-id=847%3A0
        body1: {
            fontSize: '1rem',
        },
        h1: {
            fontSize: '2.125rem', // 34px
        },
        h2: {
            fontSize: '1.75rem', // 28px
        },
        h3: {
            fontSize: '1.375rem', // 22px
        },
        h4: {
            fontSize: '1.25rem', // 20px
        },
        caption: {
            fontSize: '0.6875rem', // 11px
        },
    },
});

theme.palette.tertiary = theme.palette.augmentColor({
    main: colors.primary.navy,
});

export { theme };
