import { bookingRouteConfigs } from 'consts';
import { PrivateLayout } from 'pages';
import { VFC } from 'react';
import { Route } from 'react-router-dom';

export const PrivateRouter: VFC = () => {
    console.log('entre2');
    return (
        <PrivateLayout>
            {Object.entries(bookingRouteConfigs)
                .filter(([_route, { privateRoute }]) => privateRoute)
                .map(([route, { component }]) => (
                    <Route
                        component={component}
                        exact={true}
                        path={route}
                        key={route}
                    />
                ))}
        </PrivateLayout>
    );
};
