import * as Sentry from '@sentry/react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import satisfies from 'semver/functions/satisfies';
import validVersion from 'semver/functions/valid';
import Countly from 'countly-sdk-web';

import { defaultFeatureFlags } from 'consts';
import { CountlyRemoteConfig, FeatureFlags, MessageBase } from 'types';
import { FeatureFlagsContext, FeatureFlagsContextType } from './feature-flags-context';
import { appConfig } from 'utilities';

const FeatureFlagsProvider: FC = ({ children }) => {
    const [nativeAppVersion, setNativeAppVersion] = useState<string | undefined>(undefined);
    const [featureFlagsMap, setFeatureFlagsMap] = useState<FeatureFlags | undefined>(undefined);

    const hasFeature: FeatureFlagsContextType['hasFeature'] = useCallback(
        (featureKey) => {
            // If no app version received from native we use default feature flags.
            if (!featureFlagsMap || nativeAppVersion === undefined || !validVersion(nativeAppVersion)) {
                return defaultFeatureFlags[featureKey][appConfig.platform];
            }

            const versions = featureFlagsMap[featureKey][appConfig.platform];
            return versions.length > 0 && satisfies(nativeAppVersion, versions.join('||'));
          },
        [nativeAppVersion, featureFlagsMap]
    );

    const contextValue = useMemo(() => ({
        hasFeature
    }), [hasFeature]);

    const listenToInitEvent = useCallback((event: CustomEvent<MessageBase<{ version: string }>>) => {
        setNativeAppVersion(event.detail.body?.version);
    }, []);

    useEffect(() => {
        document.addEventListener('onInit', listenToInitEvent as EventListener);
        return () => document.removeEventListener('onInit', listenToInitEvent as EventListener);
    }, [listenToInitEvent]);

    useEffect(() => {
        Countly.fetch_remote_config?.((error, remoteConfigs?: CountlyRemoteConfig) => {            
            if (!error && remoteConfigs?.feature_flags_map) {
                setFeatureFlagsMap(remoteConfigs.feature_flags_map);
            } else {
                Sentry.captureException(error ?? 'Failed to load countly remote config');
            }
        });
    }, []);

    return (
        <FeatureFlagsContext.Provider value={contextValue}>
            {children}
        </FeatureFlagsContext.Provider>
    );
};

export { FeatureFlagsProvider };
