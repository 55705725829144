import type { CognitoConfig } from 'types';

const prodAWSConfig: CognitoConfig = {
    aws_project_region: 'us-east-1',
    aws_cognito_identity_pool_id: 'us-east-1:2bb1a1f5-a483-47ca-bae2-fcfd4666a66a',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_GpqPE1uDT',
    aws_user_pools_web_client_id: '2qtlef0uavna05t1tjt7funuho',
    storage: sessionStorage,
};

export { prodAWSConfig };
