import { Card, Typography, List, Box } from '@material-ui/core';
import { Button } from '@village/ui';
import styled from 'styled-components';
import { WarningRounded } from '@material-ui/icons';

const CardRounded = styled(Card).attrs(() => ({ elevation: 0 }))`
    border-radius: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(3)} 0 0`};
    flex-grow: 1;
    padding: ${({ theme }) => ` ${theme.spacing(3)} ${theme.spacing(2)}`};
`;

const Title = styled(Typography).attrs(() => ({ variant: 'h3' }))`
    color: ${({ theme }) => theme.colors.primary.navy};
    font-weight: 600;
    margin-bottom: ${({ theme }) => theme.spacing(1)};
    line-height: 1.3;
    font-size: 1.375rem;
`;

const Subtitle = styled(Typography).attrs(() => ({ variant: 'body1' }))`
    color: ${({ theme }) => theme.colors.primary.navy80};
    line-height: 1.15;
`;

const TitleWrapper = styled(Box)`
    padding: ${({ theme }) => ` ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`};
`;

const ErrorIcon = styled(WarningRounded)`
    width: ${({ theme }) => theme.spacing(2)};
    height: ${({ theme }) => theme.spacing(1.75)};
    margin-right: ${({ theme }) => theme.spacing(0.75)}; ;
`;

const ProviderImage = styled.img.attrs(() => ({ loading: 'lazy' }))`
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
`;

const DepartmentAddress = styled(Typography)`
    color: ${({ theme }) => theme.colors.neutral.gray60};
`;

const DepartmentCity = styled(Typography)`
    font-weight: 600;
    line-height: 1.375;
    color: ${({ theme }) => theme.colors.neutral.gray90};
`;

const DepartmentImage = styled.img.attrs(() => ({ loading: 'lazy' }))`
    height: 3.5rem;
    width: 100%;
`;

/**
 * List component for different height children
 */
const ListItems = styled.div`
    display: grid;
    overflow: auto;
    grid-row-gap: 0.75rem;
`;

const DateTimeWrapper = styled(Typography)<{ readonly $isCanceled?: boolean; }>`
    text-decoration: ${({ $isCanceled }) => $isCanceled ? 'line-through' : 'none'};
    line-height: 1.25rem;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.primary.navy};
    font-weight: 600;
    margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

const PastAppointmentDateTimeWrapper = styled(DateTimeWrapper)`
    color: ${({ theme }) => theme.colors.neutral.gray70};
`;

const ScrollList = styled(List)`
    height: 100%;
    overflow: auto;
`;

const TabPanel = styled.div<{ readonly hidden: boolean }>`
    display: ${({ hidden }) => (hidden ? 'none' : 'block')};
    background-color: ${({ theme }) => theme.colors.general.white};
    flex: 1;
    overflow: auto;
`;

const StyledListItem = styled.li`
    padding: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(2)}`};
    cursor: pointer;
    
    .MuiListItemAvatar-root {
        margin: 0 ${({ theme }) => theme.spacing(2)} 0 0;
        min-width: 3rem;
    }
    .MuiListItemText-root {
        margin: 0 0 0.25rem 0;
    }
`;

const IconButton = styled(Button)`
    border-radius: 100%;
    border-width: 0.125rem;
    min-width: 0;
    padding: ${({ theme }) => theme.spacing(1)};
`;

const ButtonBox = styled(Box)`
    color: ${({ theme }) => theme.vui.colors.primary.primaryBlue};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const CenteredImageContainer = styled.div<{ size?: string }>`
    position: relative;
    overflow: hidden;
    height: ${({ size }) => size ?? '100%'};
    width: ${({ size }) => size ?? '100%'};
`;

const CircleCenteredImageContainer = styled(CenteredImageContainer)`
    border-radius: 50%;
`;

const CenteredImage = styled.img.attrs(() => ({ loading: 'lazy' }))`
    position: absolute;
    min-width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export {
    CardRounded,
    ListItems,
    ProviderImage,
    ScrollList,
    Subtitle,
    Title,
    TitleWrapper,
    DepartmentAddress,
    DepartmentCity,
    DepartmentImage,
    TabPanel,
    StyledListItem,
    DateTimeWrapper,
    PastAppointmentDateTimeWrapper,
    IconButton,
    ButtonBox,
    ErrorIcon,
    CenteredImageContainer,
    CircleCenteredImageContainer,
    CenteredImage
};
