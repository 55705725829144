"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalStyles = void 0;
var polished_1 = require("polished");
var styled_components_1 = require("styled-components");
exports.GlobalStyles = styled_components_1.createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["", ";html,body,#root{margin:0;padding:0;width:100%;height:100%;overflow:hidden;}html{font-size:16px;}body{color:", ";}*,*::before,*::after{box-sizing:border-box;}ul[class],ol[class]{padding:0;}body,h1,h2,h3,h4,p,ul[class],ol[class],li,figure,figcaption,blockquote,dl,dd{margin:0;}*{-webkit-font-smoothing:subpixel-antialiased;-moz-osx-font-smoothing:subpixel-antialiased;}"], ["", "", ""])), polished_1.normalize(), function (_a) {
    var theme = _a.theme;
    return theme.vui.colors.general.black;
});
var templateObject_1;
