"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationOutlinedIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var svg_icon_1 = require("../components/svg-icon");
exports.LocationOutlinedIcon = react_1.forwardRef(function (props, ref) { return (jsx_runtime_1.jsxs(svg_icon_1.SvgIcon, __assign({ ref: ref }, props, { viewBox: "0 0 24 24" }, { children: [jsx_runtime_1.jsx("path", { d: "M19.7275 10.3638C19.7275 16.0908 12.3638 21 12.3638 21C12.3638 21 5 16.0908 5 10.3638C5 8.41078 5.77582 6.53777 7.1568 5.1568C8.53777 3.77582 10.4108 3 12.3638 3C14.3168 3 16.1898 3.77582 17.5707 5.1568C18.9517 6.53777 19.7275 8.41078 19.7275 10.3638V10.3638Z", stroke: "currentColor", strokeWidth: "1.8", strokeLinecap: "round", strokeLinejoin: "round" }, void 0), jsx_runtime_1.jsx("path", { d: "M12.3638 12.8184C13.7194 12.8184 14.8184 11.7194 14.8184 10.3638C14.8184 9.00814 13.7194 7.90918 12.3638 7.90918C11.0081 7.90918 9.90918 9.00814 9.90918 10.3638C9.90918 11.7194 11.0081 12.8184 12.3638 12.8184Z", stroke: "currentColor", strokeWidth: "1.8", strokeLinecap: "round", strokeLinejoin: "round" }, void 0)] }), void 0)); });
