import type { FC } from 'react';
import { memo, useEffect } from 'react';

import * as Styled from './styles';

interface Props {
    readonly title: string;
    readonly placeholders: {
        readonly main: JSX.Element | string;
    };
}

const Page: FC<Props> = memo(({ placeholders, title }) => {
    useEffect(() => {
        document.title = `Village Medical Scheduling | ${title}`;
    }, [title]);

    return <Styled.Main>{placeholders.main}</Styled.Main>;
});

export { Page };
