import jwt from 'jsonwebtoken';

class User {
    public gender = '';
    public termsVersion = '';
    public firstName = '';
    public lastName = '';
    public dateOfBirth = '';
    public phone = '';
    public email = '';

    get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    initializeUserData(idToken?: string) {
        if (idToken) {
            const userData = jwt.decode(idToken);
            Object.entries(userData)?.forEach(([key, value]: [key: string, value: unknown]) => {
                if (key.includes('custom:') || key === 'email') {
                    const attributeName = key.replace('custom:', '');
                    this[attributeName] = value;
                }
            });
        }
    }

    cognitoSync() {
        //TODO: this will help to update any information on Cognito if needed. This will depend on implementation.
    }

    clearUserData() {
        Object.keys(this).forEach(attributeName => {
            this[attributeName] = '';
        });
    }
}

const i = new User();

export { i as userInstance };