"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChevronRightIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var svg_icon_1 = require("../components/svg-icon");
exports.ChevronRightIcon = react_1.forwardRef(function (props, ref) { return (jsx_runtime_1.jsx(svg_icon_1.SvgIcon, __assign({ ref: ref }, props, { viewBox: "0 0 8 15" }, { children: jsx_runtime_1.jsx("path", { d: "M1.53033 0.469182C1.23744 0.176288 0.762563 0.176288 0.46967 0.469182C0.176777 0.762075 0.176777 1.23695 0.46967 1.52984L1.53033 0.469182ZM7 6.99951L7.53033 7.52984C7.82322 7.23695 7.82322 6.76207 7.53033 6.46918L7 6.99951ZM0.46967 12.4692C0.176777 12.7621 0.176777 13.2369 0.46967 13.5298C0.762563 13.8227 1.23744 13.8227 1.53033 13.5298L0.46967 12.4692ZM0.46967 1.52984L6.46967 7.52984L7.53033 6.46918L1.53033 0.469182L0.46967 1.52984ZM6.46967 6.46918L0.46967 12.4692L1.53033 13.5298L7.53033 7.52984L6.46967 6.46918Z", fill: "currentColor" }, void 0) }), void 0)); });
