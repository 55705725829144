"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tabTheme = void 0;
var colors_1 = require("./colors");
exports.tabTheme = {
    background: colors_1.colors.secondary.whiteCatskill,
    default: {
        color: colors_1.colors.neutral.gray60,
    },
    indicator: {
        color: colors_1.colors.primary.turquoise,
    },
    selected: {
        color: colors_1.colors.primary.navy,
    },
};
