import type { FC } from 'react';
import { memo } from 'react';

import { BookingDashboardPage } from './dashboard-page';

import { Page } from 'pages/layouts';

const placeholders = {
    main: <BookingDashboardPage />,
};

const DashboardPage: FC = memo(() => <Page placeholders={placeholders} title="Login" />);

export { DashboardPage };
