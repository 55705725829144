import type { CognitoConfig } from 'types';

const uatAWSConfig: CognitoConfig = {
    aws_project_region: 'us-east-1',
    aws_cognito_identity_pool_id: 'us-east-1:9e03af59-c35f-49c6-aaea-88d82dbfac54',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_T4HxfdfzK',
    aws_user_pools_web_client_id: '5j3hkp927vcr32j7pfs636b9ds',
    storage: sessionStorage,
};

export { uatAWSConfig };
