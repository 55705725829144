"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.typographyTheme = void 0;
var polished_1 = require("polished");
exports.typographyTheme = {
    android: {
        body1: {
            fontSize: polished_1.rem(16),
            fontWeight: '400',
            lineHeight: '1.5',
        },
        body2: {
            fontSize: polished_1.rem(14),
            fontWeight: '400',
            lineHeight: '1.4',
        },
        caption: {
            fontSize: polished_1.rem(12),
            fontWeight: '400',
            lineHeight: '1.3',
        },
        h1: {
            fontSize: polished_1.rem(34),
            fontWeight: '700',
            lineHeight: '1.2',
        },
        h2: {
            fontSize: polished_1.rem(28),
            fontWeight: '500',
            lineHeight: '1.2',
        },
        h3: {
            fontSize: polished_1.rem(24),
            fontWeight: '500',
            lineHeight: '1.25',
        },
        h4: {
            fontSize: polished_1.rem(20),
            fontWeight: '500',
            lineHeight: '1.3',
        },
        sub1: {
            fontSize: polished_1.rem(16),
            fontWeight: '500',
            lineHeight: '1.375',
        },
        sub2: {
            fontSize: polished_1.rem(14),
            fontWeight: '500',
            lineHeight: '1.4',
        },
        titleCaps: {
            fontSize: polished_1.rem(14),
            fontWeight: '500',
            lineHeight: '1.4',
        },
    },
    ios: {
        body1: {
            fontSize: polished_1.rem(16),
            fontWeight: '400',
            lineHeight: '1.5',
        },
        body2: {
            fontSize: polished_1.rem(14),
            fontWeight: '400',
            lineHeight: '1.4',
        },
        caption: {
            fontSize: polished_1.rem(12),
            fontWeight: '400',
            lineHeight: '1.3',
        },
        h1: {
            fontSize: polished_1.rem(34),
            fontWeight: '700',
            lineHeight: '1.2',
        },
        h2: {
            fontSize: polished_1.rem(28),
            fontWeight: '600',
            lineHeight: '1.2',
        },
        h3: {
            fontSize: polished_1.rem(24),
            fontWeight: '600',
            lineHeight: '1.25',
        },
        h4: {
            fontSize: polished_1.rem(20),
            fontWeight: '500',
            lineHeight: '1.3',
        },
        sub1: {
            fontSize: polished_1.rem(16),
            fontWeight: '600',
            lineHeight: '1.375',
        },
        sub2: {
            fontSize: polished_1.rem(14),
            fontWeight: '600',
            lineHeight: '1.4',
        },
        titleCaps: {
            fontSize: polished_1.rem(14),
            fontWeight: '600',
            lineHeight: '1.4',
        },
    },
};
