import { Button, Input } from '@village/ui';
import { useCallback, useState, VFC, useEffect, ChangeEvent, KeyboardEvent, useMemo } from 'react';
import { Box, IconButton, InputAdornment, useTheme } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';

import * as Primitives from 'components/primitives';
import { useAuthActions, useAuthState } from 'hooks';
import { CenteredSpinner } from 'components';

const LoginPageContent: VFC = () => {
    const location = useLocation();
    const theme = useTheme();
    const authActions = useAuthActions();
    const { isLoggedIn, isLoggingIn, error } = useAuthState();
    const [password, setPassword] = useState<{ value: string; showPassword: boolean }>({
        value: '',
        showPassword: false,
    });
    const [email, setEmail] = useState<string>('');
    const { replace } = useHistory();
    const handleLogin = useCallback(() => {
        authActions.login(email, password.value);
    }, [authActions, email, password.value]);

    const redirectTo = useMemo(() => new URLSearchParams(location.search).get('redirectTo'), [location.search]);

    //authActions.logout();

    useEffect(() => {
        if (isLoggedIn) {
            replace(redirectTo ?? '/dashboard');
        }
    }, [isLoggedIn, replace, redirectTo]);

    if (isLoggingIn) {
        return <CenteredSpinner />;
    }

    return (
        <Box display="flex" flexDirection="column" height="100%" bgcolor={theme.colors.uncategorized.whiteCatskill} pt={2} data-test="login-page">
            <Primitives.CardRounded>
                    <Box pb={2}>
                        <Input
                            label="Email address"
                            error={!!error}
                            fullWidth
                            type="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            onKeyPress={(event) => event.key === 'Enter' && handleLogin()}
                            data-test="email"
                        />
                    </Box>
                    <Box pb={2}>
                        <Input
                            label="Password"
                            error={!!error}
                            fullWidth
                            type={password.showPassword ? 'text' : 'password'}
                            value={password.value}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword({ ...password, value: event.target.value })}
                            onKeyPress={(event: KeyboardEvent<HTMLInputElement>) => event.key === 'Enter' && handleLogin()}
                            data-test="password"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setPassword({ ...password, showPassword: !password.showPassword })}
                                        onMouseDown={(event) => event.preventDefault()}
                                        edge="end"
                                    >
                                        {password.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Box>
                    <Box>
                        <Button onClick={handleLogin} type="submit" fullWidth={true} data-test="submit-signin">
                            Log in
                        </Button>
                    </Box>
            </Primitives.CardRounded>
        </Box>
    );
};

export { LoginPageContent };
