import ReactDOM from 'react-dom';
import { initIpc, initSentry, initCognito } from 'utilities';
import { App } from 'components';

if (!window.Cypress) {
    initSentry();
}

initIpc();
initCognito();

ReactDOM.render(<App />, document.getElementById('root'));
