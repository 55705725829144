"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var svg_icon_1 = require("../components/svg-icon");
exports.BookingIcon = react_1.forwardRef(function (props, ref) { return (jsx_runtime_1.jsxs(svg_icon_1.SvgIcon, __assign({ ref: ref }, props, { viewBox: "0 0 22 22" }, { children: [jsx_runtime_1.jsx("path", { d: "M17.286 17.286v1.571h1.933a4.668 4.668 0 01-3.505 1.572A4.72 4.72 0 0111 15.714H9.429a6.273 6.273 0 0011 4.137v2.15H22v-4.715h-4.714zM15.714 9.429A6.317 6.317 0 0011 11.578v-2.15H9.429v4.715h4.714v-1.571H12.21A4.666 4.666 0 0115.714 11a4.72 4.72 0 014.715 4.714H22a6.294 6.294 0 00-6.286-6.285z", fill: "#626A74" }, void 0), jsx_runtime_1.jsx("path", { d: "M7.857 20.429H3.143v-3.143h1.571v-1.572H3.143v-3.928h1.571v-1.572H3.143V6.286h1.571V4.714H3.143V1.571h14.143v6.286h1.571V1.571A1.571 1.571 0 0017.286 0H3.143A1.571 1.571 0 001.57 1.571v3.143H0v1.572h1.571v3.928H0v1.572h1.571v3.928H0v1.572h1.571v3.143A1.571 1.571 0 003.143 22h4.714v-1.571z", fill: "#626A74" }, void 0)] }), void 0)); });
