"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIsInteractiveElement = exports.getDividerCssWidth = void 0;
var styles_1 = require("./styles");
var getDividerCssWidth = function (hasIcon) {
    return hasIcon
        ? "calc(100% - " + styles_1.ITEM_PADDING + " - " + styles_1.ICON_PICTURE_MARGIN_RIGHT + " - " + styles_1.ICON_SIZE + ")"
        : "calc(100% - " + styles_1.ITEM_PADDING + ")";
};
exports.getDividerCssWidth = getDividerCssWidth;
var getIsInteractiveElement = function (element) { var _a, _b; return Boolean((_b = (_a = element.onClick) !== null && _a !== void 0 ? _a : element.onKeyPress) !== null && _b !== void 0 ? _b : element.onKeyUp); };
exports.getIsInteractiveElement = getIsInteractiveElement;
