"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var svg_icon_1 = require("../components/svg-icon");
exports.VideoIcon = react_1.forwardRef(function (props, ref) { return (jsx_runtime_1.jsxs(svg_icon_1.SvgIcon, __assign({ ref: ref }, props, { viewBox: "0 0 41 26" }, { children: [jsx_runtime_1.jsx("rect", { y: "4.727", width: "26", height: "21.273", rx: "1.988", fill: "#B3EBF5" }, void 0), jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.318 20.682V2.955h22.455v17.727H5.318zM2.364 1.988C2.364.89 3.254 0 4.352 0h24.387c1.098 0 1.988.89 1.988 1.988v19.66c0 1.098-.89 1.988-1.988 1.988H4.352a1.988 1.988 0 01-1.988-1.988V1.988zM36.8 3.505l-4.56 4.558v7.953l4.56 4.559c1.252 1.252 3.393.365 3.393-1.406V4.91c0-1.772-2.141-2.659-3.394-1.406z", fill: "#17335B" }, void 0)] }), void 0)); });
