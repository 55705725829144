import { user } from 'api';
import { useAuthState } from 'hooks';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const LayoutContext = createContext({});

export const LayoutProvider = ({ children }) => {
    const [layout, setLayout] = useState({});
    const { isLoggedIn } = useAuthState();
    const history = useHistory();

    const getLayout = useCallback(async () => {
        const res = await user.getLayout();
        const location = history.location.pathname
            .replace('/booking/', '')
            .replace(/([-_][a-z])/gi, ($1) => {
                return $1.toUpperCase().replace('-', '').replace('_', '');
            });
        return res?.data?.d.results[location];
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            getLayout()
                .then((res) => {
                    setLayout(res);
                })
                .catch((e) => console.log(e));
        }
    }, [isLoggedIn]);

    return (
        <LayoutContext.Provider value={{ layout }}>
            {children}
        </LayoutContext.Provider>
    );
};
