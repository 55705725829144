import { FC, useRef } from 'react';
import { appConfig } from 'utilities';
import { AuthManager } from 'utilities/AuthManager';
import './style.css';

const GetCare: FC = () => {
    const ref = useRef(null);

    return (
        <iframe
            title="scheduler"
            ref={ref}
            className="responsive-iframe"
            src={appConfig.schedulerUrl}
            onLoad={() =>
                (ref?.current as unknown as HTMLIFrameElement)?.contentWindow?.postMessage(
                    {
                        event: 'tokenSignIn',
                        body: { vmdCognito: AuthManager.cognitoToken },
                    },
                    appConfig.schedulerUrl
                )
            }
        />
    );
};

export { GetCare };
