"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationSolidIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var svg_icon_1 = require("../components/svg-icon");
exports.LocationSolidIcon = react_1.forwardRef(function (props, ref) { return (jsx_runtime_1.jsxs(svg_icon_1.SvgIcon, __assign({ ref: ref }, props, { viewBox: "0 0 16 22" }, { children: [jsx_runtime_1.jsx("path", { d: "M7.957 4a3.87 3.87 0 100 7.74 3.87 3.87 0 000-7.74zm0 6.39a2.52 2.52 0 112.52-2.52 2.52 2.52 0 01-2.52 2.513v.007z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { d: "M7.957 0A7.964 7.964 0 000 7.923c0 3.155 1.77 5.796 3.067 7.72l.236.352A67.273 67.273 0 007.45 21.4l.514.601.513-.601a67.41 67.41 0 004.147-5.404l.237-.358c1.29-1.925 3.06-4.56 3.06-7.714A7.964 7.964 0 007.957 0zm3.776 14.86l-.243.358a54.915 54.915 0 01-3.533 4.661c-.797-.966-2.37-2.918-3.533-4.66l-.236-.359C2.992 13.077 1.35 10.64 1.35 7.896a6.606 6.606 0 0113.212 0c0 2.77-1.635 5.208-2.83 6.964z", fill: "currentColor" }, void 0)] }), void 0)); });
