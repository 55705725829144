import * as Sentry from '@sentry/react';
import { matchPath } from 'react-router-dom';

import type { IpcEvents, MessageBase, RouteConfig } from 'types';
import { history } from 'routes/history';
import { bookingRouteConfigs } from 'consts';

const sendToNative = (event: IpcEvents, body?: Record<string, unknown>): void => {
    const message: MessageBase = {
        ...(body && { body }),
        event,
        sender: 'scheduler',
        timestamp: Date.now(),
    };

    try {
        const serializedMessage = JSON.stringify(message);

        if (window.webkit?.messageHandlers?.mobileListener?.postMessage) {
            window.webkit.messageHandlers.mobileListener.postMessage(serializedMessage);
        } else if (window.javascript_obj?.textFromWeb) {
            window.javascript_obj.textFromWeb(serializedMessage);
        }
    } catch (error: unknown) {
        Sentry.captureException(error);
    }
};

const getRouteConfigByPathname = (pathname: string): RouteConfig | null => {
    const matchedRoute = Object.entries(bookingRouteConfigs).find(([routeName]) => {
        return matchPath(pathname, {
            path: routeName,
            exact: true
        });
    });

    if (matchedRoute) {
        const [_routeName, routeConfig] = matchedRoute;
        return routeConfig;
    }

    return null;
};

const initIpc = (): void => {
    window.addEventListener('message', ({ origin, data }: MessageEvent<MessageBase>) => {
        if (origin === window.origin) {
            const customEvent = new CustomEvent<MessageBase>(data.event, { detail: data });
            document.dispatchEvent(customEvent);
        }
    });

    const routeConfig = getRouteConfigByPathname(window.location.pathname);

    // Initial page navigation event
    sendToNative('navigation', {
        canClose: routeConfig?.canClose !== false,
        canGoBack: history.length > 0 && routeConfig?.canGoBack !== false,
        currentPage: window.location.pathname,
        title: routeConfig?.title ?? '',
    });

    // Subsequent page navigation events
    history.listen(({ pathname, state }) => {
        const historyRouteConfig = getRouteConfigByPathname(pathname);

        sendToNative('navigation', {
            canClose: historyRouteConfig?.canClose !== false,
            canGoBack: history.length > 0 && historyRouteConfig?.canGoBack !== false,
            currentPage: pathname,
            title: state?.title ?? historyRouteConfig?.title ?? '',
        });
    });

    document.addEventListener('navigateBack', history.goBack);
};

export { initIpc, sendToNative };
