import { FC } from 'react';
import './style.css';

interface ActionCardProps {
    title: string;
    imgSrc: string
}

const ActionCard: FC<ActionCardProps> = ({ title, imgSrc }) => {

  return (
    <div className="action-card">
      <div>
        <img src={imgSrc}/>
        <p>{title}</p>
      </div>
    </div>
  );
};

export { ActionCard };
