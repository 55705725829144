import axios, { AxiosInstance } from 'axios';
import type { CognitoUser } from 'amazon-cognito-identity-js';
import jwt from 'jsonwebtoken';
import { getSessionStorage, setSessionStorage } from './storage';
import { user } from 'api';
import { appConfig } from './app-config';

export class AuthManager {
    protected static accessToken = '';
    protected static ttl = 0;
    protected static refreshToken = '';
    private static idToken = '';
    private static vmdCognito = {};

    static async login(email: string, password: string): Promise<CognitoUser> {
        const result = await axios.post(`${appConfig.identityServiceUrl}/login`, {
            email,
            password,
        });

        if (result) {
            const { AccessToken, ExpiresIn, RefreshToken, IdToken } =
                result.data?.AuthenticationResult;
            this.accessToken = AccessToken;
            this.ttl = ExpiresIn;
            this.refreshToken = RefreshToken;
            this.idToken = IdToken;
            setSessionStorage('vmdCognito', {
                accessToken: this.accessToken,
                idToken: this.idToken,
                refreshToken: this.refreshToken,
                lastAuthUser: email,
            });
            setSessionStorage('accessToken', this.accessToken);
            setSessionStorage('idToken', this.idToken);
            setSessionStorage('refreshToken', this.refreshToken);
            user.careGoal = this.getAuthenticatedAxiosInstance(
                `${appConfig.careGoalUrl}user/v1`
            );
        }
        //const userData = {}
        return jwt.decode(this.idToken);
    }

    static get cognitoToken() {
        return this.vmdCognito;
    }

    static currentAuthenticatedUser(): Promise<CognitoUser> {
        //TODO:
        const idToken = getSessionStorage<string>('idToken');
        const accessToken = getSessionStorage<string>('accessToken');
        const refreshToken = getSessionStorage<string>('refreshToken');
        const vmdCognito = getSessionStorage<string>('vmdCognito');
        this.accessToken = accessToken || '';
        this.refreshToken = refreshToken || '';
        this.idToken = idToken || '';
        this.vmdCognito = vmdCognito || {};
        user.careGoal = this.getAuthenticatedAxiosInstance(
            `${appConfig.careGoalUrl}user/v1`
        );

        return new Promise((resolve, reject) => {
            if (idToken) {
                resolve(jwt.decode(idToken));
            }
            reject();
        });
    }

    static async refresh() {
        //TODO:
    }

    static async logout() {
        //TODO:
        Object.keys(this).forEach((attributeName) => {
            this[attributeName] = '';
        });
        await axios.post(`${appConfig.identityServiceUrl}/logout`, {
            headers: {
                Authorization: 'Bearer ' + this.accessToken,
            },
        });
    }

    private static getAuthenticatedAxiosInstance(
        baseURL: string,
        tokenName = 'idToken'
    ): AxiosInstance {
        return this[tokenName]
            ? axios.create({
                  baseURL: baseURL,
                  headers: {
                      Authorization: `Bearer ${this[tokenName]}`,
                  },
              })
            : axios;
    }
}
