import { createContext } from 'react';
import type { FeatureFlags  } from 'types';

export interface FeatureFlagsContextType {
    readonly hasFeature: (featureKey: keyof FeatureFlags) => boolean;
}

const FeatureFlagsContext = createContext<FeatureFlagsContextType | undefined>(undefined);

export { FeatureFlagsContext };
