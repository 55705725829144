import { AxiosInstance } from 'axios';

class UserAPI {
    careGoal: AxiosInstance | null = null;

    async getLayout() {
        return await this.careGoal?.get(`layout`);
    }

    async getSettings() {
        return await this.careGoal?.get('settings');
    }

    async postSettings(body: { [k: string]: string }) {
        return await this.careGoal?.post('settings', body);
    }
}

const i = new UserAPI();

export { i as user };
