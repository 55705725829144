import { Environment } from 'enums';
import type { FeatureFlag, AppConfig } from 'types';
import { appConfig } from 'utilities';

const production = appConfig.environment === Environment.PRODUCTION;

/**
 * Default feature flags in case the `onInit` event was not received or not available (Native apps < 2.2.0 or Web).
 */
const defaultFeatureFlags: Record<FeatureFlag, Record<AppConfig['platform'], boolean>> = {
    // Disabling Add to Calendar for now because of issues with mobile adding at the incorrect time
    // https://projects.prod.villagemd.com/browse/SCHED-540
    addToCalendar: { ios: false, android: false, web: true },
    cancelAppointment: { ios: !production, android: !production, web: true },
    callUs: { ios: true, android: true, web: true },
    getDirections: { ios: false, android: true, web: true },
    joinVideoCall: { ios: false, android: false, web: true },
    nativeBottomDrawer: { ios: false, android: false, web: false },
    pastAppointment: { ios: !production, android: !production, web: true },
};

export { defaultFeatureFlags };
