"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoInternetIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var svg_icon_1 = require("../components/svg-icon");
exports.NoInternetIcon = react_1.forwardRef(function (props, ref) { return (jsx_runtime_1.jsxs(svg_icon_1.SvgIcon, __assign({ ref: ref }, props, { viewBox: "0 0 72 72" }, { children: [jsx_runtime_1.jsx("path", { d: "M60.374 32.971c3.282.235 5.06 3.69 7.286 6.072 2.227 2.383 3.34 5.215 3.34 8.496 0 3.438-1.23 6.387-3.691 8.848-2.461 2.46-5.41 3.691-8.848 3.691H26c-4.14 0-7.676-1.465-10.605-4.394C12.465 52.754 11 49.219 11 45.078c0-3.906 1.29-7.266 3.867-10.078 2.578-2.813 5.742-4.414 9.492-4.805 1.563-3.125 3.828-5.605 6.797-7.441C34.125 20.918 37.406 20 41 20c4.531 0 8.516 1.445 11.953 4.336 1.386 9.328 6.562 4.338 7.421 8.635z", fill: "#B3EBF5" }, void 0), jsx_runtime_1.jsx("path", { d: "M54.154 30.67l.224 1.12 1.14.082c3.126.223 5.71 1.452 7.824 3.715 2.113 2.262 3.158 4.916 3.158 8.038 0 3.264-1.15 6.03-3.498 8.377-2.348 2.348-5.114 3.498-8.377 3.498H20c-4.019 0-7.408-1.404-10.252-4.248C6.904 48.408 5.5 45.02 5.5 41c0-3.801 1.24-7.02 3.73-9.736 2.506-2.733 5.545-4.269 9.175-4.647l.819-.085.368-.736c1.542-3.084 3.767-5.52 6.697-7.332 2.922-1.808 6.148-2.714 9.711-2.714 4.484 0 8.39 1.42 11.785 4.273 3.413 2.87 5.521 6.406 6.37 10.646z", stroke: "#5D708C", strokeWidth: "3" }, void 0), jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M42.581 31.44a1.5 1.5 0 010 2.12l-4.818 4.819 5.082 5.081a1.5 1.5 0 11-2.121 2.121L35.642 40.5l-5.081 5.081a1.5 1.5 0 01-2.122-2.12l5.082-5.082-4.818-4.818a1.5 1.5 0 012.121-2.122l4.818 4.818 4.818-4.818a1.5 1.5 0 012.121 0z", fill: "#5D708C" }, void 0)] }), void 0)); });
