const getSessionStorage = <T = unknown>(key: string): T | null => {
    if (!key) return null;

    try {
        const sessionStorageItem = window.sessionStorage.getItem(key);

        if (sessionStorageItem?.[0] !== '{') {
            return sessionStorageItem as unknown as T;
        }
        return sessionStorageItem ? (JSON.parse(sessionStorageItem) as T) : null;
    } catch {
        return null;
    }
};

const setSessionStorage = (key: string, data: unknown): void => {
    typeof data === 'string'
        ? window.sessionStorage.setItem(key, data)
        : window.sessionStorage.setItem(key, JSON.stringify(data));
};

const removeSessionStorageItem = (key: string): void => {
    window.sessionStorage.removeItem(key);
};

export { getSessionStorage, removeSessionStorageItem, setSessionStorage };
