"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.colors = void 0;
exports.colors = {
    accent: {
        orange: '#E68842',
        orangeText: '#E68842',
        pink: '#B64878',
    },
    feedback: {
        greenSuccess: '#31AD6D',
        greenSuccessText: '#1C8750',
        redError: '#D90012',
    },
    general: {
        black: '#000000',
        white: '#FFFFFF',
    },
    neutral: {
        gray25: '#F8F8F9',
        gray5: '#F0F2F3',
        gray40: '#9299A2',
        gray60: '#626A74',
        gray75: '#424851',
        gray80: '#373D45',
        gray85: '#2D333A',
        gray90: '#24282E',
        gray95: '#1D2025',
        gray875: '#282D34',
        gray10: '#E2E4E8',
        gray30: '#ABB1B9',
    },
    primary: {
        blue: '#007CBA',
        navy: '#17335B',
        navy70: '#5D708C',
        navy80: '#455C7C',
        navy90: '#2E476B',
        primaryBlue: '#0080A3',
        turquoise: '#00BBDC',
    },
    // https://chir.ag/projects/name-that-color/
    secondary: {
        veniceBlue: '#06668A',
        ghost: '#C6CBD1',
        blueHighlight: '#00A8C6',
        whiteCatskill: '#F2F8F9',
        athensGray: '#E3E7EB',
    },
};
