import styled from 'styled-components';
import { SwipeableDrawer } from '@material-ui/core';

const StyledSwipeableDrawer = styled(SwipeableDrawer)`
    .MuiPaper-rounded {
        border-radius: 12px 12px 0 0;
        padding: ${({ theme }) => theme.spacing(3)};
    }
`;

export { StyledSwipeableDrawer };
