import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components/macro';

const StyledSvgIcon = styled(SvgIcon)`
    height: ${({ height }) => height ?? '1rem'};
    width: ${({ width }) => width ?? '1rem'};
    fill: none;
`;

export { StyledSvgIcon };
