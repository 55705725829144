import { useContext } from 'react';

import { AuthActionsContext } from 'components';
import type { AuthActions } from 'types';

const useAuthActions = (): AuthActions => {
    const context = useContext(AuthActionsContext);

    if (context === undefined) {
        throw new Error('useAuthActions must be used within a AuthProvider');
    }
    return context;
};

export { useAuthActions };
