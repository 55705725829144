import { VFC } from 'react';
import { Box, useTheme } from '@material-ui/core';

import * as Primitives from 'components/primitives';
import { useAuthState } from 'hooks';

export const BookingDashboardPage: VFC = () => {
    const theme = useTheme();
    const { authUser } = useAuthState();

    return (
        <Box
            display="flex"
            flexDirection="column"
            height="100%"
            bgcolor={theme.colors.uncategorized.whiteCatskill}
            pt={2}
            data-test="login-page"
        >
            <Primitives.CardRounded>
                <p>{`Hi, ${authUser?.['custom:firstName']} ${authUser?.['custom:lastName']}`}</p>
            </Primitives.CardRounded>
        </Box>
    );
};
