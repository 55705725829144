"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuildingOutlinedIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var svg_icon_1 = require("../components/svg-icon");
exports.BuildingOutlinedIcon = react_1.forwardRef(function (props, ref) { return (jsx_runtime_1.jsx(svg_icon_1.SvgIcon, __assign({ ref: ref }, props, { viewBox: "0 0 19 22" }, { children: jsx_runtime_1.jsx("path", { d: "M12 8H14V10H12V8ZM14 4H12V6H14V4ZM8 14H10V12H8V14ZM10 4H8V6H10V4ZM8 10H10V8H8V10ZM6 4H4V6H6V4ZM6 8H4V10H6V8ZM11.55 20H10V16.5H8V20H2V2H16V10.03C16.71 10.09 17.39 10.31 18 10.63V0H0V22H12.91C12.5 21.44 12 20.76 11.55 20ZM4 18H6V16H4V18ZM6 12H4V14H6V12ZM19 15.5C19 18.1 15.5 22 15.5 22C15.5 22 12 18.1 12 15.5C12 13.6 13.6 12 15.5 12C17.4 12 19 13.6 19 15.5ZM16.7 15.6C16.7 15 16.1 14.4 15.5 14.4C14.9 14.4 14.3 14.9 14.3 15.6C14.3 16.2 14.8 16.8 15.5 16.8C16.2 16.8 16.8 16.2 16.7 15.6Z", fill: "currentColor" }, void 0) }), void 0)); });
