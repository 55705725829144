import type { RouteConfig, Route } from 'types';
import {
    LoginPage
} from 'pages';
import { DashboardPage } from 'pages/dashboard';
import { GetCare } from 'components';
import { ProfilePage } from 'pages/profile';

/**
 * Static route config for each page, specifying:
 *     - Should the native back button be used for FE navigation or native navigation?
 *     - Should the native close button be visible?
 *     - Should the page have a title in the native navbar?
 *     - Should the route require authentication to be viewed?
 *     - What component should the route render?
 */
const bookingRouteConfigs: Record<Route, RouteConfig> = {
    '/': {
        canGoBack: false,
        step: 'LOGIN',
        component: LoginPage,
        privateRoute: false,
    },
    '/dashboard': {
        canGoBack: false,
        step: 'DASHBOARD',
        component: DashboardPage,
        privateRoute: true,
    },
    '/get-care': {
        canGoBack: false,
        step: 'SELECT_REASON', 
        component: GetCare,
        privateRoute: true,
    },
    '/profile': {
        canGoBack: false,
        step: 'PROFILE', 
        component: ProfilePage,
        privateRoute: true,
    }
};

export { bookingRouteConfigs };
