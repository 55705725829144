"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChevronLeftIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var svg_icon_1 = require("../components/svg-icon");
exports.ChevronLeftIcon = react_1.forwardRef(function (props, ref) { return (jsx_runtime_1.jsx(svg_icon_1.SvgIcon, __assign({ ref: ref }, props, { viewBox: "0 0 8 15" }, { children: jsx_runtime_1.jsx("path", { d: "M6.46967 13.9625C6.76256 14.2554 7.23744 14.2554 7.53033 13.9625C7.82322 13.6696 7.82322 13.1947 7.53033 12.9018L6.46967 13.9625ZM1 7.43213L0.46967 6.9018C0.176777 7.19469 0.176777 7.66957 0.46967 7.96246L1 7.43213ZM7.53033 1.96246C7.82322 1.66957 7.82322 1.19469 7.53033 0.901799C7.23744 0.608906 6.76256 0.608906 6.46967 0.901799L7.53033 1.96246ZM7.53033 12.9018L1.53033 6.9018L0.46967 7.96246L6.46967 13.9625L7.53033 12.9018ZM1.53033 7.96246L7.53033 1.96246L6.46967 0.901799L0.46967 6.9018L1.53033 7.96246Z", fill: "currentColor" }, void 0) }), void 0)); });
