import type { FC } from 'react';
import { memo } from 'react';

import { ProfilePageContent } from './profile-page';

import { Page } from 'pages/layouts';

const placeholders = {
    main: <ProfilePageContent />,
};

const ProfilePage: FC = memo(() => <Page placeholders={placeholders} title="Login" />);

export { ProfilePage };
