"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BandAidIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var svg_icon_1 = require("../components/svg-icon");
exports.BandAidIcon = react_1.forwardRef(function (props, ref) { return (jsx_runtime_1.jsxs(svg_icon_1.SvgIcon, __assign({ ref: ref }, props, { viewBox: "0 0 58 58" }, { children: [jsx_runtime_1.jsx("path", { d: "M33.726 10.513c4.652-4.652 11.906-4.94 16.202-.645 4.295 4.296 4.007 11.55-.645 16.202L26.82 48.532c-4.652 4.652-11.905 4.94-16.201.645-4.296-4.296-4.007-11.55.645-16.202l22.462-22.462z", fill: "#B3EBF5" }, void 0), jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M42.263 23.879l3.064-3.065c3.906-3.905 3.906-10.236 0-14.142-3.905-3.905-10.237-3.905-14.142 0l-3.064 3.065L26 11.857 11.858 26l-2.122 2.121-3.064 3.064c-3.905 3.906-3.905 10.237 0 14.143 3.905 3.905 10.237 3.905 14.142 0l3.064-3.065L26 40.144 40.142 26l2.121-2.121zM44.385 26l3.064-3.064c5.076-5.077 5.076-13.308 0-18.385-5.077-5.077-13.308-5.077-18.385 0L26 7.615l-3.064-3.064C17.859-.526 9.628-.526 4.55 4.551c-5.077 5.077-5.077 13.308 0 18.385L7.615 26 4.55 29.064c-5.077 5.077-5.077 13.308 0 18.385 5.077 5.077 13.308 5.077 18.385 0L26 44.385l3.064 3.064c5.077 5.077 13.308 5.077 18.385 0 5.076-5.077 5.076-13.308 0-18.385L44.385 26zm-2.122 2.121L28.121 42.264l3.064 3.064c3.905 3.905 10.237 3.905 14.142 0 3.906-3.906 3.906-10.237 0-14.143l-3.064-3.064zM9.736 23.88L23.878 9.737l-3.064-3.065c-3.905-3.905-10.237-3.905-14.142 0-3.905 3.906-3.905 10.237 0 14.143l3.064 3.064zM24.45 21.35a2.192 2.192 0 103.1-3.1 2.192 2.192 0 00-3.1 3.1zm-6.2 6.2a2.192 2.192 0 103.1-3.1 2.192 2.192 0 00-3.1 3.1zm15.5 0a2.192 2.192 0 11-3.1-3.1 2.192 2.192 0 013.1 3.1zm-9.3 6.2a2.192 2.192 0 103.1-3.1 2.192 2.192 0 00-3.1 3.1z", fill: "#5D708C" }, void 0)] }), void 0)); });
