"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CrossIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var svg_icon_1 = require("../components/svg-icon");
exports.CrossIcon = react_1.forwardRef(function (props, ref) { return (jsx_runtime_1.jsxs(svg_icon_1.SvgIcon, __assign({ ref: ref }, props, { viewBox: "0 0 24 24" }, { children: [jsx_runtime_1.jsx("path", { d: "M18.6 16.121a1.512 1.512 0 1 1-2.139 2.14l-4.376-4.377-4.377 4.376a1.512 1.512 0 1 1-2.139-2.139l4.377-4.376-4.377-4.377A1.512 1.512 0 1 1 7.71 5.23l4.376 4.377 4.377-4.377A1.513 1.513 0 0 1 18.6 7.37l-4.377 4.376 4.377 4.376Z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { d: "M18.6 16.121a1.512 1.512 0 1 1-2.139 2.14l-4.376-4.377-4.377 4.376a1.512 1.512 0 1 1-2.139-2.139l4.377-4.376-4.377-4.377A1.512 1.512 0 1 1 7.71 5.23l4.376 4.377 4.377-4.377A1.513 1.513 0 0 1 18.6 7.37l-4.377 4.376 4.377 4.376Z", stroke: "currentColor" }, void 0)] }), void 0)); });
