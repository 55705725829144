import type { VFC } from 'react';
import { Box } from '@material-ui/core';
import { Spinner } from '@village/ui';

const CenteredSpinner: VFC = () =>
    <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
        <Box height="6rem" width="6rem" display="flex" justifyContent="center" alignItems="center">
            <Spinner />
        </Box>
    </Box>;

export { CenteredSpinner };
