"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.blendColors = void 0;
var polished_1 = require("polished");
var blendColors = function (backgroundColor, foregroundColor) {
    var bottomColorRgb = polished_1.parseToRgb(backgroundColor);
    var topColorRgba = polished_1.parseToRgb(foregroundColor);
    if (!('alpha' in topColorRgba)) {
        return polished_1.rgbToColorString(topColorRgba);
    }
    var newColor = {
        blue: Math.round(bottomColorRgb.blue + (topColorRgba.blue - bottomColorRgb.blue) * topColorRgba.alpha),
        green: Math.round(bottomColorRgb.green + (topColorRgba.green - bottomColorRgb.green) * topColorRgba.alpha),
        red: Math.round(bottomColorRgb.red + (topColorRgba.red - bottomColorRgb.red) * topColorRgba.alpha),
    };
    return polished_1.rgbToColorString(newColor);
};
exports.blendColors = blendColors;
