"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStatus = void 0;
// Error status should override all other states.
var getStatus = function (props) {
    if (props.error) {
        return 'error';
    }
    // disabled and readOnly states are handled the same way.
    if (props.disabled || props.readOnly) {
        return 'disabled';
    }
    return 'default';
};
exports.getStatus = getStatus;
