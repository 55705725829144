import { useContext } from 'react';

import { AuthStateContext } from 'components';
import type { AuthState } from 'types';

const useAuthState = (): AuthState => {
    const context = useContext(AuthStateContext);

    if (context === undefined) {
        throw new Error('useAuthState must be used within a AuthProvider');
    }
    return context;
};

export { useAuthState };
