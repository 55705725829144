import { bookingRouteConfigs } from 'consts';
import { VFC } from 'react';
import { Route } from 'react-router-dom';

export const PublicRouter: VFC = () => {
    console.log('entre2');
    return (
        <>
            {Object.entries(bookingRouteConfigs)
                .filter(([_route, { privateRoute }]) => !privateRoute)
                .map(([route, { component }]) => (
                    <Route
                        component={component}
                        exact={true}
                        path={route}
                        key={route}
                    />
                ))}
        </>
    );
};
