"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chipTheme = void 0;
var colors_1 = require("./colors");
exports.chipTheme = {
    variant: {
        default: {
            primary: {
                backgroundColor: colors_1.colors.primary.primaryBlue,
                color: colors_1.colors.general.white,
                border: 'transparent',
                hoverBgColor: colors_1.colors.secondary.blueHighlight,
            },
            default: {
                backgroundColor: colors_1.colors.neutral.gray5,
                color: colors_1.colors.neutral.gray80,
                border: 'transparent',
                hoverBgColor: colors_1.colors.neutral.gray40,
            },
        },
        outlined: {
            primary: {
                backgroundColor: colors_1.colors.general.white,
                color: colors_1.colors.primary.primaryBlue,
                border: colors_1.colors.primary.primaryBlue,
                hoverBgColor: colors_1.colors.neutral.gray40,
            },
            default: {
                backgroundColor: colors_1.colors.general.white,
                color: colors_1.colors.neutral.gray80,
                border: colors_1.colors.neutral.gray80,
                hoverBgColor: colors_1.colors.neutral.gray40,
            },
        },
    },
};
