import { useAuthState } from 'hooks';
import { VFC } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { PrivateRouter } from './PrivateRouter';
import { PublicRouter } from './PublicRouter';
import { RouterManager } from './RouterManager';

export const MainRouter: VFC = () => {
    const { isLoggedIn } = useAuthState();

    return (
        <BrowserRouter>
            <Switch>
                <RouterManager>
                    {!isLoggedIn ? <PublicRouter /> : <PrivateRouter />}
                </RouterManager>
            </Switch>
        </BrowserRouter>
    );
};
