import { FC } from 'react';
//import CSS from 'csstype';
import { ActionCard } from 'components';
import './style.css';

/*
const mainContainer: CSS.Properties = {
  height: '100%',
  display: 'flex'
};*/

const PrivateLayout: FC = ({ children }) => {
    return (
        <div id="wrapper">
            <div id="sidebar-wrapper">
                <ul className="sidebar-nav">
                    <li>
                        <img
                            className="left-logo"
                            src="https://www.villagemedical.com/hubfs/villagemedical/VMedical%20%20Logo_RGB_Color_horizontal.png"
                            width="75%"
                            alt="village-medical"
                        />
                        <a
                            href="dashboard"
                            data-toggle="collapse"
                            data-target="#drop1"
                        >
                            Dashboard<span className="caret"></span>
                        </a>
                        <ul
                            id="drop1"
                            className="collapse"
                            data-parent="#sideNavParent"
                        >
                            <li>
                                <a href="get-care">Get Care</a>
                            </li>
                            <li>
                                <a href="profile">Profile</a>
                            </li>
                        </ul>
                    </li>

                    <div className="action-card-layout">
                        <ActionCard
                            title="Book Visit"
                            imgSrc="https://is3-ssl.mzstatic.com/image/thumb/Purple126/v4/82/61/91/8261912d-d564-19b7-9d0e-398b15a91d14/AppIcon-0-1x_U007emarketing-0-10-0-85-220.png/460x0w.webp"
                        />
                        <ActionCard
                            title="Live Care"
                            imgSrc="https://is3-ssl.mzstatic.com/image/thumb/Purple126/v4/82/61/91/8261912d-d564-19b7-9d0e-398b15a91d14/AppIcon-0-1x_U007emarketing-0-10-0-85-220.png/460x0w.webp"
                        />
                        <ActionCard
                            title="View Labs"
                            imgSrc="https://is3-ssl.mzstatic.com/image/thumb/Purple126/v4/82/61/91/8261912d-d564-19b7-9d0e-398b15a91d14/AppIcon-0-1x_U007emarketing-0-10-0-85-220.png/460x0w.webp"
                        />
                        <ActionCard
                            title="Learn"
                            imgSrc="https://is3-ssl.mzstatic.com/image/thumb/Purple126/v4/82/61/91/8261912d-d564-19b7-9d0e-398b15a91d14/AppIcon-0-1x_U007emarketing-0-10-0-85-220.png/460x0w.webp"
                        />
                    </div>
                </ul>
            </div>

            <div id="page-content-wrapper">
                <div className="container-fluid">
                    <div className="columns">
                        <div className="col-100 bg-teal">{children}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { PrivateLayout };
