"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonCard = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var Styled = __importStar(require("./styles"));
exports.ButtonCard = react_1.memo(function (props) {
    var _a;
    var _b = props.selected, selected = _b === void 0 ? false : _b, picture = props.picture, primaryText = props.primaryText, children = props.children, _c = props.pictureType, pictureType = _c === void 0 ? 'square' : _c, containerProps = __rest(props, ["selected", "picture", "primaryText", "children", "pictureType"]);
    var pictureSize = 'pictureSize' in props ? (_a = props.pictureSize) !== null && _a !== void 0 ? _a : 'normal' : 'normal';
    var hasPicture = Boolean(picture);
    return (jsx_runtime_1.jsxs(Styled.ButtonCard, __assign({ "$selected": selected, "$pictureSize": pictureSize, "$hasPicture": hasPicture }, containerProps, { children: [jsx_runtime_1.jsx("div", { children: hasPicture ? (jsx_runtime_1.jsx(Styled.PictureContainer, __assign({ "$pictureSize": pictureSize, "$pictureType": pictureType }, { children: picture }), void 0)) : null }, void 0), jsx_runtime_1.jsxs("div", { children: [jsx_runtime_1.jsx(Styled.Title, { children: primaryText }, void 0), jsx_runtime_1.jsx(Styled.Content, { children: children }, void 0)] }, void 0)] }), void 0));
});
