"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inputTheme = void 0;
var colors_1 = require("./colors");
exports.inputTheme = {
    default: {
        default: {
            backgroundColor: colors_1.colors.general.white,
            borderColor: colors_1.colors.neutral.gray30,
            borderWidth: 1,
            textColor: colors_1.colors.neutral.gray80,
        },
        focus: {
            backgroundColor: colors_1.colors.general.white,
            borderColor: colors_1.colors.primary.primaryBlue,
            borderWidth: 2,
            textColor: colors_1.colors.neutral.gray80,
        },
        hover: {
            backgroundColor: colors_1.colors.general.white,
            borderColor: colors_1.colors.neutral.gray60,
            borderWidth: 1,
            textColor: colors_1.colors.neutral.gray80,
        },
    },
    disabled: {
        default: {
            backgroundColor: colors_1.colors.neutral.gray25,
            borderColor: colors_1.colors.neutral.gray30,
            borderWidth: 1,
            textColor: colors_1.colors.neutral.gray40,
        },
        focus: {
            backgroundColor: colors_1.colors.neutral.gray25,
            borderColor: colors_1.colors.neutral.gray30,
            borderWidth: 1,
            textColor: colors_1.colors.neutral.gray40,
        },
        hover: {
            backgroundColor: colors_1.colors.neutral.gray25,
            borderColor: colors_1.colors.neutral.gray30,
            borderWidth: 1,
            textColor: colors_1.colors.neutral.gray40,
        },
    },
    error: {
        default: {
            backgroundColor: colors_1.colors.general.white,
            borderColor: colors_1.colors.feedback.redError,
            borderWidth: 2,
            textColor: colors_1.colors.neutral.gray80,
        },
        focus: {
            backgroundColor: colors_1.colors.general.white,
            borderColor: colors_1.colors.feedback.redError,
            borderWidth: 2,
            textColor: colors_1.colors.neutral.gray80,
        },
        hover: {
            backgroundColor: colors_1.colors.general.white,
            borderColor: colors_1.colors.feedback.redError,
            borderWidth: 2,
            textColor: colors_1.colors.neutral.gray80,
        },
    },
};
